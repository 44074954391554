import React, { useRef, useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";


export default function Title({ contentDelay }) {
    const ref = useRef();
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start start", "end start"]
    });


    const opacity = useTransform(scrollYProgress, [0, 0.4], [1, 0]);
    const scale = useTransform(scrollYProgress, [0, 0.7], [1, 0]);

    let [lightRayOpacity, setLightRayOpacity] = useState(0);

    useEffect(() => {
        let lightDelay = contentDelay + 0.5;
        if (!lightDelay) {
            lightDelay = 0;
        }
        if (contentDelay) {
            setTimeout(updateLightRayOpacity, lightDelay*1000 + 500);
            setTimeout(updateLightRayOpacity, lightDelay*1000 + 550);
            setTimeout(updateLightRayOpacity, lightDelay*1000 + 600);
            setTimeout(updateLightRayOpacity, lightDelay*1000 + 700);
            setTimeout(updateLightRayOpacity, lightDelay*1000 + 750);
            setTimeout(updateLightRayOpacity, lightDelay*1000 + 800);
            setTimeout(updateLightRayOpacity, lightDelay*1000 + 1100);
        }
        else {
            setLightRayOpacity(1);
        }
    }, []);

    function updateLightRayOpacity() {
        setLightRayOpacity(prevLightRayOpacity => {
            let newLightRayOpacity = 0;
            if (!prevLightRayOpacity) {
                newLightRayOpacity = 1;
            }
            return newLightRayOpacity;
        })
    }

    // og
    // const title = {
    //     initial: { 
    //         y: contentDelay ? "-50vh" : 0
    //     },
    //     animate: {
    //         y: 0,
    //         transition: {
    //             ease: [0, 0.75, 0.38, 1],
    //             delay: 0.5,
    //             duration: 0.8
    //         }
    //     }
    // }

    // og
    // const mask = {
    //     initial: {
    //         clipPath: contentDelay ? "inset(0 80% 0 0)" : "inset(0 0 0 0)"
    //     },
    //     animate: {
    //         clipPath: "inset(0 0 0 0)",
    //         transition: {
    //             ease: [0.8, 0, 0.2, 0.85],
    //             delay: contentDelay ? 0.8 : 0,
    //             duration: 1.5 
    //         }
    //     }
    // }

    const titleTop = {
        initial: {
            x: contentDelay ? "85vw" : 0
        },
        animate: {
            x: 0,
            transition: {
                ease: [0, 0.75, 0.38, 1],
                delay: 0.5,
                duration: 1.5
            }
        }
    }

    const titleBottom = {
        initial: {
            x: contentDelay ? "-85vw" : 0
        },
        animate: {
            x: 0,
            transition: {
                ease: [0, 0.75, 0.38, 1],
                delay: 0.5,
                duration: 1.5
            }
        }
    }

    const maskTop = {
        initial: {
            clipPath: contentDelay ? "inset(0 80% 0 0)" : "inset(0 0 0 0)"
        },
        animate: {
            clipPath: "inset(0 0 0 0)",
            transition: {
                ease: [0.8, 0, 0.2, 0.85],
                delay: contentDelay ? 0.8 : 0,
                duration: 1.5 
            }
        }
    }

    const maskBottom = {
        initial: {
            clipPath: contentDelay ? "inset(0 0 0 88%)" : "inset(0 0 0 0)"
        },
        animate: {
            clipPath: "inset(0 0 0 0)",
            transition: {
                ease: [0.8, 0, 0.2, 0.85],
                delay: contentDelay ? 0.8 : 0,
                duration: 1.5 
            }
        }
    }

    const fadeIn = {
        initial: { 
            opacity: 0
        },
        animate: {
            opacity: 1,
            transition: {
                delay: contentDelay,
                duration: 1
            }
        }
    }


    return (
        <motion.div
            className="relative"
            ref={ref}
            style={{
                opacity: opacity,
                scale: scale
            }}
        >
            <img
                style={{
                    opacity: lightRayOpacity,
                    transform: 'scaleX(1.3)'
                }}
                src="/images/homeLightRayStretched.png"
                alt="glow effect"
                className="absolute lg:w-[60%] w-4/5 xl:w-1/2 xl:left-[25.5%] lg:left-[20.5%] left-[10.5%] top-[75px]"
            />

            <motion.div 
                className="pt-40 xs:pt-48 sm:pt-56"
                variants={titleTop}
                initial="initial"
                animate="animate"
            >
                <motion.p 
                    className="md:text-8xl sm:text-7xl xs:text-6xl text-5xl font-bold w-fit mx-auto bg-gradient-to-r from-[#aaaaaa] via-[#dddddd] to-[#aaaaaa] text-transparent bg-clip-text"
                    variants={maskTop}
                    initial="initial"
                    animate="animate"
                >
                    SKULE
                </motion.p>
            </motion.div>

            <motion.div 
                variants={titleBottom}
                initial="initial"
                animate="animate"
            >
                <motion.p 
                    className="md:text-8xl sm:text-7xl xs:text-6xl text-5xl font-bold w-fit mx-auto bg-gradient-to-r from-[#aaaaaa] via-[#dddddd] to-[#aaaaaa] text-transparent bg-clip-text"
                    variants={maskBottom}
                    initial="initial"
                    animate="animate"
                >
                    TRACKER
                </motion.p>
            </motion.div>

            <motion.p 
                className="font-semibold md:text-2xl sm:text-xl text-lg w-fit mx-auto bg-gradient-to-r from-[#aaaaaa] via-[#dddddd] to-[#aaaaaa] text-transparent bg-clip-text text-center"
                variants={fadeIn}
                initial="initial"
                animate="animate"
            >
                Track everything academic. All in one place.
            </motion.p>
        </motion.div>
    )
}