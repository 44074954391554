import React, { useState, useEffect } from "react";
import { motion, useAnimate, AnimatePresence } from "framer-motion";
import { db } from "../firebase";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import NavbarLink from "./NavbarLink";
import { useNavigate, Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";

export default function DashboardNavbar({ to, ignore = false }) {
    const [top, animateTop] = useAnimate();
    const [middle, animateMiddle] = useAnimate();
    const [bottom, animateBottom] = useAnimate();
    const [openMenu, setOpenMenu] = useState(false);
    const [, setUserID] = useState("");
    const [name, setName] = useState("");
    const [school, setSchool] = useState("");

    const navigate = useNavigate();

    async function handleMenuButtonAnimate() {
        if (!openMenu) {
            animateTop(top.current, { rotate: 45, y: 10 })
            animateMiddle(middle.current, { opacity: 0 })
            animateBottom(bottom.current, { rotate: -45, y: -10 })
        }
        else {
            animateTop(top.current, { rotate: 0, y: 0 })
            animateMiddle(middle.current, { opacity: 1 })
            animateBottom(bottom.current, { rotate: 0, y: 0 })
        }
        setOpenMenu(prevOpenMenu => !prevOpenMenu)
    }

    // FIREBASE STUFFS HERE
    const auth = getAuth();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {

            // if the user is signed in 
            if (user) {
                const uid = user.uid;
                setUserID(uid);
                const docRef = doc(db, "users", user.uid);
                getDoc(docRef)
                    .then((docSnap) => {
                        setName(docSnap.data().username);
                        setSchool(docSnap.data().school);
                    }).catch((error) => {
                        if (!ignore) {
                            navigate("/error");
                        }
                    }
                );
            }
                
            // if the user is NOT signed in
            else if (!user) {
                navigate("/");
            }
        });
    }, [auth, navigate, ignore]);

    function handleSignOut() {
        signOut(auth).then(() => {
            navigate("/");
        }).catch((error) => {
            navigate("/error");
        });
    }

    return (
        <div className="bg-[#121220] border-b border-[#ffffff20] w-full h-[75px] fixed top-0 z-30 font-montserrat">
            <div className="w-4/5 mx-auto h-full flex flex-row items-center justify-between">

                {/* CODE FOR THE ACTUAL MENU OPENED HERE */}
                <AnimatePresence>
                    {openMenu && (
                    <motion.div 
                        className="text-white absolute right-0 top-[75px] w-[250px] lg:w-[300px] bg-[#000000ee] text-2xl font-montserrat p-6 z-10 h-screen overflow-hidden"
                        initial={{ x: 350, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 1,
                            ease: [0.01, 1, 0.5, 1]
                        }}
                        exit={{ x: 350, opacity: 0}}
                    >
                        <NavbarLink to="/" text="HOME" /><br />
                        <NavbarLink to="/welcome" text="PROFILE" /><br />
                        <NavbarLink to="/dashboard" text="DASHBOARD" /><br />
                        <NavbarLink to="/calendar" text="CALENDAR" /><br />
                        <NavbarLink to="/calculator" text="GPA TARGET" /><br />
                        <NavbarLink to="/archive" text="THE ARCHIVE" /><br />
                        <div onClick={handleSignOut} ><NavbarLink text="SIGN OUT" /></div><br />

                    </motion.div>
                    )}
                </AnimatePresence>

                <div className="text-white w-[50px]">
                    {to ? (
                        <Link to={to} className="flex items-center">
                            <motion.button
                                initial={{ scale: 1.0 }}
                                whileHover={{
                                    scale: 1.1,
                                    transition: { duration: 0.25 }
                                }}
                                whileTap={{ 
                                    scale: 0.9,
                                    transition: { duration: 0.05 }
                                }}
                            >
                                <img 
                                    src="/images/backArrow.png"
                                    alt=""
                                    className="w-[50px]"
                                />
                            </motion.button>
                        </Link>
                    ) : (
                        <Link to="/">
                            <img 
                                src="/images/logo.png"
                                alt=""
                                className=""
                            />
                        </Link>
                    )}
                </div>
                <div className="hidden md:block text-white font-semibold text-xl">
                    <span>
                        {`${name} `}
                    </span>
                    <span>{`@ ${school}`}</span>
                </div>


                {/* CODE FOR THE MENU BUTTON ON THE NAVBAR */}
                <motion.div
                    initial={{ scale: 1.0 }}
                    whileHover={{
                        scale: 1.15,
                        transition: { duration: 0.25 }
                    }}
                    whileTap={{ 
                        scale: 0.9,
                        transition: { duration: 0.125 }
                    }}
                    className="w-[30px] h-[30px] flex flex-col justify-between py-1"
                    onClick={() => handleMenuButtonAnimate()}
                >

                    <motion.div
                        className="border border-[#ffffff70] w-full"
                        ref={top}
                    >
                    </motion.div>

                    <motion.div
                        className="border border-[#ffffff70] w-full"
                        ref={middle}
                    >
                    </motion.div>

                    <motion.div
                        className="border border-[#ffffff70] w-full"
                        ref={bottom}
                    >
                    </motion.div>

                </motion.div>

                
            </div>
        </div>
    )
}