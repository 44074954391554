export const data = [
    {
        desc: "Elevate your academic journey with SkuleTracker. Say goodbye to the stress of managing your grades - designed exclusively for students, SkuleTracker empoyers you to effortlessly monitor your academic performance, ensuring you stay on top of your studies with ease.",
        title: "Grades Organizer",
        image: "/images/grades.png"
    },
    {
        desc: "Strive for success and take control of your academic journey with the SkuleTracker GPA Target - providing a clear, effortless, and easy-to-use automatic GPA calculator! Never think to yourself \"what grades do I need?\" again - it's all done automatically right in front of you!",
        title: "GPA Target",
        image: "/images/target.png"
    },
    {
        desc: "Never miss a deadline again with SkuleTracker - seamlessly integrated, the SkuleTracker Calendar is designed to keep track of and organize your academic life with ease. You'll always be one step ahead of the game with SkuleTracker Calendar, allowing you to allocate time wisely.",
        title: "Calendar",
        image: "/images/calendar.png"
    }
];

