import React, { useEffect, useRef } from "react";
import Navbar from "../components/Navbar";
import { motion, useAnimation, useScroll, useTransform } from "framer-motion";
import "../scrollbar.css";
import RevealCard from "../components/RevealCard";
import RevealCardVid from "../components/RevealCardVid";

export default function Learnmore() {

    sessionStorage.setItem('lastPage', JSON.stringify('learnmore'));

    const controls = useAnimation();

    useEffect(() => {
        async function sequence() {
            await controls.start({
                y: 300,
                opacity: 0,
                transition: {
                    duration: 0.1
                }
            });
            window.scrollTo(0, 0);
            await new Promise(resolve => setTimeout(resolve, 10));
            await controls.start({
                y: 0,
                opacity: 1,
                transition: {
                    ease: [0.7, 0, 0, 1],
                    duration: 2,
                }
            });
        }
        sequence();
    }, [controls]);

    return (
        <div
            className="bg-[#121220] min-h-[calc((100vh)+450px)] bg-[url('/public/images/pattern.png')] bg-repeat bg-cover py-48 font-montserrat"
        >
            <Navbar />
            <motion.div
                initial={{ y: 0, opacity: 0 }}
                animate={controls}
            >

                <h1
                    className="relative text-5xl xs:text-6xl sm:text-7xl lg:text-8xl font-bold w-fit mx-auto font-montserrat bg-gradient-to-r from-[#aaaaaa] via-[#dddddd] to-[#aaaaaa] text-transparent bg-clip-text"
                >
                    SKULE
                </h1>
                <p className="text-white font-montserrat text-base xs:text-lg sm:text-xl lg:text-2xl w-4/5 mx-auto text-center border-b border-b-[#ffffff50] pb-16 lg:pb-32">
                    Stay on top of your academic game with Skule! 
                    Organize assignments, track grades, 
                    and manage your schedule effortlessly. 
                </p>

                <p className="font-montserrat text-2xl xs:text-3xl md:text-4xl lg:text-5xl w-4/5 mx-auto text-[#bbbbbb] mt-16 lg:mt-32 pb-2">Tracking your grades has never been <span className="font-bold bg-gradient-to-r from-[#aaaaaa] via-[#dddddd] to-[#aaaaaa] text-transparent bg-clip-text">easier.</span></p>

                <RevealCard 
                    src="/images/learnmore1.png"
                    text="Automatically generated summary of every course, with all the information you need in a quick glance."
                />

                <RevealCardVid 
                    src="/videos/learnmorevid1.mp4"
                    text="Keep track of every assessment's grade and due date with ease. Didn't recieve your mark back as a percentage? Skule will automatically calculate it for you!"
                />

                <RevealCard 
                    src="/images/learnmore4.png"
                    text="With your course information entered, Skule will automatically compute your GPA for you, and tell you what you need to hit your targets."
                />

                <p className="font-montserrat text-2xl xs:text-3xl md:text-4xl lg:text-5xl w-4/5 mx-auto text-[#bbbbbb] mt-20 lg:mt-40 border-t border-t-[#ffffff50] pt-16 lg:pt-32 pb-2">Your due dates have never been <span className="font-bold bg-gradient-to-r from-[#aaaaaa] via-[#dddddd] to-[#aaaaaa] text-transparent bg-clip-text">clearer.</span></p>

                <RevealCard 
                    src="/images/learnmore3.png"
                    text="Your assessments get automatically entered into the Skule Calendar - no need to set up twice! Mark assessments as completed as you finish them to keep track of what's due and what's done. "
                />

                <RevealCardVid 
                    src="/videos/learnmorevid2.mp4"
                    text="Have an assessment that got extended? Easily move around your due dates with drag and drop!"
                />

            </motion.div>
        </div>
    )
}