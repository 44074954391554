import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Popup from "../components/Popup";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import LoadingSpinner from "../components/LoadingSpinner";

export default function Welcome() {
    const [userID, setUserID] = useState(null);
    const [welcome, setWelcome] = useState(false);
    const [formData, setFormData] = useState(
        {
            username: "",
            school: ""
        }
    );
    const [popup, setPopup] = useState({
        visible: false,
        text: "",
        image: ""
    });

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    /**
     * 
     * @param {string} text 
     * @param {string} image 
     */
    function handlePopup(text, image) {
        if (!popup.visible) {
            setPopup(() => {
                return {
                    visible: true,
                    text: text,
                    image: image
                }
            });
            setTimeout(() => setPopup({
                visible: false,
                text: "",
                image: ""
            }), 3000);
        }
        else {
            setPopup(() => {
                return {
                    visible: true,
                    text: text,
                    image: image
                }
            });
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        const nameRegex = /^[0-9a-z-_ ]+$/i;
        const schoolRegex = /^[a-z ']+$/i;
        const validName = nameRegex.test(formData.username);
        const validSchool = schoolRegex.test(formData.school);
        if (formData.username.length === 0) {
            handlePopup("Username cannot be empty", "/images/warning.png");
        }
        else if (formData.school.length === 0) {
            handlePopup("School name canont be empty", "/images/warning.png");
        }
        else if (!validName) {
            handlePopup("Names can only contain letters, numbers, hyphens or underscores.", "/images/warning.png");
        }
        else if (!validSchool) {
            handlePopup("School names can only contain letters and apostrophes", "/images/warning.png");
        }
        else {
            handleUpdateProfile();
        }
    }

    async function handleUpdateProfile() {
        setIsLoading(true);
        const docRef = doc(db, "users", userID);
        await updateDoc(docRef, {
            welcome: false,
            username: formData.username,
            school: formData.school,
        });
        navigate("/dashboard");
        
        setIsLoading(false);
    }

    function handleChange(event) {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        });
    }

    const initialVariant = {
        initial: {
            y: welcome ? 400 : 0,
            opacity: welcome ? 0 : 1,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                ease: [0.7, 0, 0, 1],
                duration: 2,
            }
        }
    }

    const delayVariant = {
        initial: {
            y: 100,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                ease: [0.7, 0, 0, 1],
                delay: welcome ? 2 : 0,
                duration: 1.5,
            }
        }
    }

    const mask = {
        initial: {
            clipPath: "inset(0 100% 0 0)"
        },
        animate: {
            clipPath: "inset(0 0 0 0)",
            transition: {
                ease: [0.8, 0, 0.2, 1],
                delay: welcome ? 1.2 : 0,
                duration: 1.5 
            }
        }
    }

    const auth = getAuth();
    useEffect(() => {
        if (JSON.parse(sessionStorage.getItem("fromAccountCreation")) === "TRUE") {
            handlePopup("Account created successfully!", "/images/checkmark.png");
            sessionStorage.setItem("fromAccountCreation", JSON.stringify("FALSE"));
        }

        onAuthStateChanged(auth, (user) => {
            // if the user is signed in 
            if (user) {
                const uid = user.uid;
                setUserID(uid);
            }
            if (user && !sessionStorage.getItem("welcome")) {
                const docRef = doc(db, "users", user.uid);
                getDoc(docRef)
                    .then((docSnap) => {
                        if (docSnap.data().welcome) {
                            setWelcome(true);
                        }
                        else {
                            setWelcome(false);
                        }
                    }).catch((error) => {
                        navigate("/error");
                    }
                )
            }
    
            // if the user is NOT signed in
            else if (!user) {
                navigate("/");
            }
        })
    }, []);

    sessionStorage.setItem('lastPage', JSON.stringify('welcome'));

    return (
        <div className="w-full font-montserrat relative top-[75px] flex flex-col lg:flex-row overflow-x-hidden">
            {isLoading && <LoadingSpinner />}
            <Navbar opaque="true" />
            {/* left half */}
            <div className="bg-[#121220] w-full lg:w-[40%] lg:h-full lg:min-h-[calc(100dvh-75px)] relative text-white">
                <div className="">
                    <div className="lg:w-4/5 xs:w-[60%] w-[75%] h-1/2 mx-auto lg:mx-0 lg:ml-auto lg:mt-32 mt-8">
                        <motion.p 
                            className="text-2xl lg:text-3xl xl:text-4xl font-semibold lg:w-[90%] w-full lg:py-2 py-1"
                            variants={initialVariant}
                            initial="initial"
                            animate="animate"
                        >
                            Profile
                        </motion.p>
                        <motion.div 
                            className="border-t border-t-2 w-full lg:w-[90%]"
                            variants={mask}
                            initial="initial"
                            animate="animate"
                        ></motion.div>
                        <motion.form 
                            className="text-white lg:mt-8 mt-4"
                            variants={delayVariant}
                            initial="initial"
                            animate="animate"
                        >
                            <div>
                                <label className="text-sm lg:text-xl xl:text-2xl">
                                    Display Name<br />
                                    <input 
                                        type="text" 
                                        name="username" 
                                        onChange={handleChange}
                                        value={formData.username}
                                        autoComplete="off"
                                        placeholder="Username"
                                        className="bg-[#121220] border-b border-[#aaaaaa80] focus:outline-none focus:border-b-[#cccccc] lg:text-xl text-base py-1 lg:w-[90%] w-full"
                                    />
                                </label>
                            </div>
                            <div className="lg:mt-8 mt-4">
                                <label className="text-sm lg:text-xl xl:text-2xl">
                                    Institution:<br />
                                    <input 
                                        type="text" 
                                        name="school" 
                                        onChange={handleChange}
                                        value={formData.school}
                                        autoComplete="off"
                                        placeholder="School Name"
                                        className="bg-[#121220] border-b border-[#aaaaaa80] focus:outline-none focus:border-b-[#cccccc] lg:text-xl text-base py-1 lg:w-[90%] w-full"
                                    />
                                </label>
                            </div>
                            <div className="flex xl:flex-row lg:flex-col xs:flex-row flex-col items-center justify-between lg:mt-8 mt-6 lg:w-[90%] w-full mb-32">
                                <motion.button 
                                    initial={{ scale: 1.0 }}
                                    whileHover={{
                                        scale: 1.05,
                                        transition: { duration: 0.25 }
                                    }}
                                    whileTap={{ 
                                        scale: 0.95,
                                        transition: { duration: 0.05 }
                                    }}
                                    onClick={handleSubmit}
                                    className="border-4 py-2 2xl:px-12 px-4 tracking-wide font-semibold sm:font-bold text-base sm:text-2xl w-full text-black bg-[#FFA600]"
                                >
                                    {welcome ? "GET STARTED" : "UPDATE PROFILE"}
                                </motion.button>
                            </div>
                        </motion.form>
                    </div>
                </div>
            </div>

            {/* right half */}
            <div className="w-full lg:w-[60%] h-[50dvh] lg:h-auto flex-grow bg-[url('/public/images/verticalLoginBG.png')] lg:bg-[url('/public/images/horizontalLoginBG.png')] bg-no-repeat bg-cover relative">
            </div>

            {/* POPUP WARNING SHTS */}
            <AnimatePresence>
            {popup.visible && (
                <Popup text={popup.text} image={popup.image} />
            )}
            </AnimatePresence>
        </div>
    )
}