import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { db } from "../firebase";
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from "firebase/auth";
import Popup from "../components/Popup";
import { doc, getDoc, setDoc } from "firebase/firestore";
import LoadingSpinner from "../components/LoadingSpinner";

export default function Signup() {
    sessionStorage.setItem('lastPage', JSON.stringify('signup'));

    const navigate = useNavigate();

    const [formData, setFormData] = useState(
        {
            email: "",
            password: "",
            confirmPassword: "",
            remember: false
        }
    );

    const [isLoading, setIsLoading] = useState(false);

    const [popup, setPopup] = useState({
        visible: false,
        text: "",
        image: ""
    });

    /**
     * 
     * @param {string} text 
     * @param {string} image 
     */
    function handlePopup(text, image) {
        if (!popup.visible) {
            setPopup(() => {
                return {
                    visible: true,
                    text: text,
                    image: image
                }
            });
            setTimeout(() => setPopup({
                visible: false,
                text: "",
                image: ""
            }), 3000);
        }
        else {
            setPopup(() => {
                return {
                    visible: true,
                    text: text,
                    image: image
                }
            });
        }
    }

    function handleChange(event) {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        });
    }

    function handleSubmit(event) {
        event.preventDefault()
        if (formData.password !== formData.confirmPassword) {
            handlePopup("Passwords do not match", "/images/warning.png");
        }
        else if (formData.password.length < 8) {
            handlePopup("Password needs to be at least 8 characters", "/images/warning.png");
        }
        else if (formData.password.length > 48) {
            handlePopup("Passwords cannot be longer than 48 characters", "/images/warning.png");
        }
        else {
            // password fields are valid
            authCreateAccountWithEmail();
        }
    }
    
    function handleSubmitWithGoogle(event) {
        event.preventDefault();
        authSignInWithGoogle();
    }

    function clearAuthFields() {
        setFormData(
            {
                email: "",
                password: "",
                confirmPassword: "",
                remember: false
            }
        )
    }

    // FIREBASE STUFFS ARE HERE ------------------------------------------------------------------------------------------------------------
    const auth = getAuth();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            // if the user is signed in 
            if (user) {
                const uid = user.uid;
                const docRef = doc(db, "users", user.uid);
                getDoc(docRef)
                    .then((docSnap) => {
                        if (docSnap.exists() && !docSnap.data().welcome) {
                            navigate("/dashboard");
                        }
                    }
                );
            }
        });
    }, []);

    const provider = new GoogleAuthProvider();

    async function onFirstSignIn(userCredential) {
        try {
            setIsLoading(true);
            sessionStorage.setItem("welcome", true);
            await setDoc(doc(db, "users", userCredential.user.uid), {
                welcome: true,
                courses: [],
                customGPAScale: false,
                plannedCredits: 120,
                gpatarget: 3.0,
                showTooltipMessage: true
            });
            navigate("/welcome");
            setIsLoading(false);
        } catch (error) {
            console.error('Error setting document:', error);
            navigate("/error");
        }
    }

    function authCreateAccountWithEmail() {
        const email = formData.email;
        const password = formData.password;

        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                sessionStorage.setItem('fromAccountCreation', JSON.stringify('TRUE'));
                clearAuthFields();
                onFirstSignIn(userCredential);
            })
            .catch((error) => {
                if (error.message === "Firebase: Error (auth/missing-email).") {
                    handlePopup("Please provide an email", "/images/warning.png");
                } 
                else if (error.message === "Firebase: Error (auth/invalid-email).") {
                    handlePopup("Invalid email provided", "/images/warning.png");
                }
                else if (error.message === "Firebase: Error (auth/email-already-in-use).") {
                    handlePopup("Email is already registered", "/images/warning.png");
                }
                else {
                    handlePopup("Something went wrong", "/images/warning.png");
                }
            }
        )
    }

    function authSignInWithGoogle() {
        signInWithPopup(auth, provider)
            // upon valid sign in
            .then((userCredential) => {
                clearAuthFields();

                // creating a ref to the user's data
                const docRef = doc(db, "users", userCredential.user.uid);
                
                // getting the data belonging to the user 
                getDoc(docRef)
                    .then((docSnap) => {
                        if (!docSnap.exists() || docSnap.data().welcome) {
                            onFirstSignIn(userCredential)
                        }
                        else {
                            navigate("/dashboard");
                        }
                    })
                    .catch((error) => {
                        handlePopup("Something went wrong", "/images/warning.png");
                    }
                )
            })
            // upon bad sign in
            .catch((error) => {
                handlePopup("Invalid Credentials", "/images/warning.png");
            }
        )
    }


    return (
        <div className="w-full font-montserrat relative top-[75px] flex flex-col lg:flex-row overflow-x-hidden">
            {isLoading && <LoadingSpinner />}
            <Navbar opaque="true" />
            {/* left half */}
            <div className="bg-[#121220] w-full lg:w-[40%] lg:h-full lg:min-h-[calc(100dvh-75px)] relative text-white">
                <div className="">
                    <div className="lg:w-4/5 xs:w-[60%] w-[75%] h-1/2 mx-auto lg:mx-0 lg:ml-auto lg:mt-16 mt-8">
                        <p className="text-2xl lg:text-3xl xl:text-4xl font-semibold border-b lg:w-[90%] w-full lg:py-2 py-1">Account Sign Up</p>
                        <form className="text-white lg:mt-8 mt-4">
                            <div>
                                <label className="text-sm lg:text-xl xl:text-2xl">
                                    Email:<br />
                                    <input 
                                        type="text" 
                                        name="email" 
                                        onChange={handleChange}
                                        value={formData.email}
                                        autoComplete="off"
                                        placeholder="Email Address"
                                        className="bg-[#121220] border-b border-[#aaaaaa80] focus:outline-none focus:border-b-[#cccccc] lg:text-xl text-base py-1 lg:w-[90%] w-full"
                                    />
                                </label>
                            </div>
                            <div className="lg:mt-8 mt-4">
                                <label className="text-sm lg:text-xl xl:text-2xl">
                                    Password:<br />
                                    <input 
                                        type="password" 
                                        name="password" 
                                        onChange={handleChange}
                                        value={formData.password}
                                        autoComplete="off"
                                        placeholder="Password"
                                        className="bg-[#121220] border-b border-[#aaaaaa80] focus:outline-none focus:border-b-[#cccccc] lg:text-xl text-base py-1 lg:w-[90%] w-full"
                                    />
                                </label>
                            </div>
                            <div className="lg:mt-8 mt-4">
                                <label className="text-sm lg:text-xl xl:text-2xl">
                                    Confirm Password:<br />
                                    <input 
                                        type="password" 
                                        name="confirmPassword" 
                                        onChange={handleChange}
                                        value={formData.confirmPassword}
                                        autoComplete="off"
                                        placeholder="Confirm Password"
                                        className="bg-[#121220] border-b border-[#aaaaaa80] focus:outline-none focus:border-b-[#cccccc] lg:text-xl text-base py-1 lg:w-[90%] w-full"
                                    />
                                </label>
                            </div>
                            <div className="lg:w-[90%] w-full lg:mt-4 mt-2">
                                <Link to="/login">
                                    <motion.p 
                                        className="text-[#aaaaaa80] lg:text-base xs:text-sm text-[10px] underline w-fit"
                                        initial={{ scale: 1.0 }}
                                        whileHover={{
                                            scale: 1.05,
                                            transition: { duration: 0.25 }
                                        }}
                                        whileTap={{ 
                                            scale: 0.9,
                                            transition: { duration: 0.125 }
                                        }}
                                    >
                                        Already have an account? Log in here!
                                    </motion.p>
                                </Link>
                            </div>
                            <div className="flex xl:flex-row lg:flex-col xs:flex-row flex-col items-center justify-between lg:mt-8 mt-6 lg:w-[90%] w-full">
                                <motion.button 
                                    initial={{ scale: 1.0 }}
                                    whileHover={{
                                        scale: 1.05,
                                        transition: { duration: 0.25 }
                                    }}
                                    whileTap={{ 
                                        scale: 0.95,
                                        transition: { duration: 0.125 }
                                    }}
                                    onClick={handleSubmit}
                                    className="border-4 py-2 2xl:px-12 px-4 tracking-wide font-semibold sm:font-bold text-base sm:text-2xl w-full"
                                >
                                    SIGN UP
                                </motion.button>
                            </div>
                            <div className="xs:mt-8 mt-4 lg:w-[90%] w-full">
                                <div className="flex flex-row justify-between items-center xs:mb-8 mb-4">
                                    <div className="w-[42%] border-t border-[#aaaaaa80] h-[1px]"></div>
                                    <p className="font-montserrat text-[#aaaaaa80]">OR</p>
                                    <div className="w-[42%] border-t border-[#aaaaaa80] h-[1px]"></div>
                                </div>
                                <motion.button
                                    initial={{ scale: 1.0 }}
                                    whileHover={{
                                        scale: 1.05,
                                        transition: { duration: 0.25 }
                                    }}
                                    whileTap={{ 
                                        scale: 0.95,
                                        transition: { duration: 0.125 }
                                    }}
                                    onClick={handleSubmitWithGoogle}
                                    className="border-4 bg-[#FFA600] w-full text-black text-2xl font-semibold p-2 mb-0 lg:mb-16"
                                >
                                    <div className="flex gap-4 lg:gap-0 items-center justify-around lg:w-full w-fit mx-auto lg:mx-0">
                                        <img 
                                            src="/images/googleLogo.png"
                                            alt=""
                                            className="xl:w-[40px] md:w-[30px] w-[20px]"    
                                        />
                                        <p className="hidden xs:block xl:pr-4 pr-0 text-base md:text-lg md:tracking-wide lg:text-base lg:tracking-tight xl:tracking-wide xl:text-xl 2xl:text-2xl">SIGNUP WITH GOOGLE</p>
                                        <p className="block xs:hidden text-sm">GOOGLE SIGNUP</p>
                                    </div>
                                </motion.button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* right half */}
            <div className="w-full lg:w-[60%] h-[50dvh] lg:h-auto flex-grow bg-[url('/public/images/verticalLoginBG.png')] lg:bg-[url('/public/images/horizontalLoginBG.png')] bg-no-repeat bg-cover relative">
            </div>

            {/* POPUP WARNING SHTS */}
            <AnimatePresence>
            {popup.visible && (
                <Popup text={popup.text} image={popup.image} />
            )}
            </AnimatePresence>
        </div>
    )
}