import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { exists, formatNumber } from "../pages/CoursePage";

// map these course cards in the parent Dashboard component, pass all required information
// into the course card as props.
export default function CourseCard({ index, name, target, avg, 
                                    weightAchieved, weightPossible, 
                                    handleArchiveClick, fromArchive }) {
    
    let bufferRaw;
    let buffer;
    if (exists(target) && exists(weightAchieved) && exists(weightPossible)) {
        bufferRaw = (100 - Number(target)) - (Number(weightPossible) - Number(weightAchieved));
        buffer = formatNumber(bufferRaw);

        if (buffer <= 0) {
            buffer = 0;
        }
    } 
    else {
        buffer = false
    }

    const navigate = useNavigate();

    const complete = Number(weightAchieved) > Number(target);
    // const className = complete ? "border-8 h-64 bg-black flex flex-col xs:gap-2 gap-4 border-[#adc698]" : "border-8 h-64 bg-black flex flex-col xs:gap-2 gap-4";
    const classNameText = `font-montserrat sm:text-5xl xs:text-4xl text-3xl font-bold w-fit mx-auto mt-2 ${complete ? "text-[#d0e3c4]" : ""}`
    const classNameBorder = `border-8 sm:h-64 bg-black flex flex-col xs:gap-2 gap-1 ${complete ? "border-[#d0e3c4]" : ""}`
    const classNameLine = `w-full border-t border-t-2 sm:mt-2 h-full flex flex-col justify-around ${complete ? "border-t-[#d0e3c4]" : ""}`

    const archiveButtonClassName = `${fromArchive ? "bg-[url(/public/images/nonArchiveIcon.png)]" : "bg-[url(/public/images/archiveIcon.png)]"} w-[25px] h-[25px] sm:w-[38px] sm:h-[38px] fixed top-2 right-2 xs:top-4 xs:right-4 sm:top-3 sm:right-3 bg-cover`

    return (
        <motion.div 
            className={classNameBorder}
            initial={{ scale: 1.0 }}
            whileHover={{
                scale: 1.02,
                transition: { duration: 0.25 }
            }}
            whileTap={{ 
                scale: 0.98,
                transition: { duration: 0.125 }
            }}
            onClick={() => navigate(`/dashboard/${index}`)}
        >
            <button 
                className={archiveButtonClassName}
                onClick={(e) => {
                    e.stopPropagation();
                    handleArchiveClick(index)
                }}
            ></button>
            <p className={classNameText}>{name}</p>
            <p className="font-dosis xs:text-lg text-base xs:tracking-widest tracking-wide w-fit mx-auto">TARGET: {`${exists(target) ? target : 'N/A'}${exists(target) ? "%" : ''}`}</p>
            <p className="font-dosis xs:text-lg text-base xs:tracking-widest tracking-wide w-fit mx-auto">CURRENT AVERAGE: {`${exists(avg) && !isNaN(avg) ? avg : 'N/A'}${exists(avg) && !isNaN(avg) ? "%" : ''}`}</p>
            <p className="font-dosis xs:text-lg text-base xs:tracking-widest tracking-wide w-fit mx-auto">WEIGHT ACHIEVED: {`${exists(weightAchieved) ? formatNumber(weightAchieved) : "N"}/${exists(weightPossible) ? formatNumber(weightPossible) : "A"}`}</p>
            <div className={classNameLine}>
                <p className="font-dosis xs:text-lg text-base xs:tracking-widest tracking-wide w-fit mx-auto py-2 sm:py-1">REMAINING BUFFER ZONE: {`${exists(buffer) ? formatNumber(buffer) : "N/A"}${exists(buffer) ? '%' : ''}`}</p>
            </div>
        </motion.div>  
    )
}