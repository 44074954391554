import React, { useEffect, useState, useRef, useCallback } from "react";
import DashboardNavbar from "../components/DashboardNavbar";
import { eachDayOfInterval, format, lastDayOfMonth, startOfMonth, startOfToday, isSameDay, startOfWeek, endOfWeek, sub, add, isAfter, isBefore } from "date-fns";
import { motion } from "framer-motion";
import { db } from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Timestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import CalendarItem from "../components/CalendarItem";
import MobileCalendarMenu from "../components/MobileCalendarMenu";
import useDisablePullToRefresh from "../hooks/useDisablePullToRefresh";
import CreateTaskMenu from "../components/CreateTaskMenu";
import CustomCalendarItem from "../components/CustomCalendarItem";
import EditTaskMenu from "../components/EditTaskMenu";

export default function Calendar() {
    
    const today = startOfToday();
    const [currentMonth, setCurrentMonth] = useState(startOfMonth(today));
    const [userID, setUserID] = useState("");
    const [, setCourseData] = useState([]);
    const [hoverDay, setHoverDay] = useState(null);

    // theses are either false, or they hold the day information of the task in question as a JS Date 
    const [showAddCustomTask, setShowAddCustomTask] = useState(false);
    const [showEditCustomTask, setShowEditCustomTask] = useState(false);

    // index of the task that is being edited
    const [editIndex, setEditIndex] = useState(null);

    const [taskInfo, setTaskInfo] = useState({
        name: "",
        class: "",
        comments: "",
        complete: false,
        date: null
    });

    
    // draggedItem is the data of the item that is being dragged
    const [draggedItem, setDraggedItem] = useState(null);

    // for custom tasks, draggedIndex is the index of the dragged task
    const [draggedIndex, setDraggedIndex] = useState(null);

    // complete holds all of the complete statuses of the assessment items.
    // triple nested object: { courseIndex: { sectionIndex: { dataIndex: true/false }}}
    const [, setComplete] = useState({});

    // startingCoords is where the mouse was when starting to drag
    const [startingCoords, setStartingCoords] = useState([]);

    const [assessmentData, setAssessmentData] = useState([]);
    const [taskData, setTaskData] = useState([]);

    const [test, setTest] = useState(0);

    const [additionalWeeks, setAdditionalWeeks] = useState(0);

    // const [childHandleCloseFunction, setChildHandleCloseFunction] = useState(null);

    // menuDay will be the day opened in the mobile calendar menu. False if 
    // the mobile calendar menu is not opened.
    const [menuDay, setMenuDay] = useState(false);

    // const calendarRef = useRef(null);        // not needed ig if we are not using dragConstraints

    const navigate = useNavigate();

    const observer = useRef();
    const lastDayRef = useCallback(node => {
        if (observer.current) {
            observer.current.disconnect();
        }
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setAdditionalWeeks(prevWeeks => {
                    const newWeeks = prevWeeks + 4;
                    return newWeeks;
                });
            }
        });
        if (node) {
            observer.current.observe(node);
        }
    }, []);

    useDisablePullToRefresh(menuDay !== false);

    function getAssessmentDataArray(courseData) {
        const tempAssessmentData = [];
        for (let i = 0; i < courseData.length; i++) {
            for (let j = 0; j < (courseData[i] ? courseData[i].sections.length : 0); j++) {
                for (let k = 0; k < (courseData[i].sections[j] ? courseData[i].sections[j].data.length : 0); k++) {
                    tempAssessmentData.push({
                        name: courseData[i].sections[j].data[k].name,
                        dueDate: new Date(courseData[i].sections[j].data[k].dueDate ? (courseData[i].sections[j].data[k].dueDate.seconds * 1000) : 0),
                        section: courseData[i].sections[j].name,
                        className: courseData[i].name,
                        courseIndex: i,
                        sectionIndex: j,
                        dataIndex: k,
                        complete: courseData[i].sections[j].data[k].complete
                    });
                }
            }
        }
        return tempAssessmentData;
    }

    // firebase initial stuffs
    // uncomment this once firebase is back up
    const auth = getAuth();
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                setUserID(uid);
                const docRef = doc(db, "users", uid);

                getDoc(docRef)
                    .then((docSnap) => {
                        if (docSnap.exists()) {
                            const rawCourseData = docSnap.data().courses;
                            setCourseData(rawCourseData);
                            
                            // array holding all assessments stored to this account
                            // format is {
                            //     name:    
                            //     dueDate:  this already converted from a Firebase TimeStamp to a JavaScript Date : let dueDate = new Date(assessment.dueDate.seconds * 1000);
                            //     section:
                            //     className:
                            //     courseIndex:
                            //     sectionIndex:
                            //     dataIndex:
                            //     complete:
                            // }
                            const tempAssessmentData = [];
                            let newComplete = {};

                            for (let i = 0; i < rawCourseData.length; i++) {
                                for (let j = 0; j < (rawCourseData[i] && rawCourseData[i].sections ? rawCourseData[i].sections.length : 0); j++) {
                                    for (let k = 0; k < (rawCourseData[i].sections[j] && rawCourseData[i].sections[j].data ? rawCourseData[i].sections[j].data.length : 0); k++) {

                                        newComplete = {
                                            ...newComplete,
                                            [i]: {
                                                ...newComplete[i],
                                                [j]: {
                                                    ...newComplete[j],
                                                    [k]: rawCourseData[i].sections[j].data[k].complete
                                                }
                                            }
                                        }
                                        tempAssessmentData.push({
                                            name: rawCourseData[i].sections[j].data[k].name,
                                            dueDate: new Date(rawCourseData[i].sections[j].data[k].dueDate ? (rawCourseData[i].sections[j].data[k].dueDate.seconds * 1000) : 0),
                                            section: rawCourseData[i].sections[j].name,
                                            className: rawCourseData[i].name,
                                            courseIndex: i,
                                            sectionIndex: j,
                                            dataIndex: k,
                                            complete: rawCourseData[i].sections[j].data[k].complete
                                        });
                                    }
                                }
                            }
                            setComplete(newComplete);
                            setAssessmentData(tempAssessmentData);

                            if (docSnap.data().tasks) {
                                setTaskData(docSnap.data().tasks);
                            }

                        }
                        else {
                            // document doesn't friking exist yet
                            console.log("doc doesnt exist yet")
                            navigate("/error");
                        }
                    }).catch((error) => {
                        // something went wrong with retrieving data
                        console.log(error.message);
                        navigate("/error");
                    }
                );
            }
            else {
                navigate("/");
            }
        });
    }, []);

    useEffect(() => {
        if (taskData.includes(taskInfo)) {
            setShowAddCustomTask(false);
        }
    }, [taskData, taskInfo]);

    useEffect(() => {
        setTaskInfo({
            name: "",
            class: "",
            comments: "",
            complete: false,
            date: null
        });
    }, [showAddCustomTask]);

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const firstOfTheMonth = startOfMonth(currentMonth);
    const lastOfTheMonth = lastDayOfMonth(currentMonth);
    const header = format(firstOfTheMonth, 'MMMM yyyy');
    
    const allDaysInCurrentMonth = eachDayOfInterval({
        start: firstOfTheMonth,
        end: lastOfTheMonth
    });

    const firstWeekStart = startOfWeek(currentMonth);
    const firstWeekEnd = sub(currentMonth, {
        days: 1
    });
    const allDaysInFirstWeek = isBefore(firstWeekEnd, firstWeekStart) ? [] : eachDayOfInterval({
        start: firstWeekStart,
        end: firstWeekEnd
    });

    const lastWeekStart = add(lastOfTheMonth, {
        days: 1
    });
    const lastWeekEnd = endOfWeek(lastOfTheMonth);
    const allDaysInLastWeek = isAfter(lastWeekStart, lastWeekEnd) ? [] : eachDayOfInterval({
        start: lastWeekStart,
        end: lastWeekEnd
    });

    const startOfNewDays = add(lastWeekEnd, { 
        days: 1
    });

    const endOfNewDays = sub(add(startOfNewDays, {
        weeks: additionalWeeks
    }), {
        days: 1 
    });

    const allAdditionalDays = eachDayOfInterval({
        start: startOfNewDays,
        end: endOfNewDays
    });

    function handleNextMonth() {
        setCurrentMonth(prev => {
            const nextMonth = add(prev, {
                months: 1
            });
            return nextMonth;
        });
    }

    function handleLastMonth() {
        setCurrentMonth(prev => {
            const lastMonth = sub(prev, {
                months: 1
            });
            return lastMonth;
        });
    }

    function handleGoToToday() {
        setCurrentMonth(() => {
            const firstOfThisMonth = startOfMonth(startOfToday());
            return firstOfThisMonth;
        });
    }

    function mapAssessments(data, current) {
        const mappedData = data.map((itemData, itemIndex) => {
            return (
                <CalendarItem 
                    key={itemIndex}
                    keyData={test + itemIndex}
                    onPointerDownFn={(event) => startDrag(event, itemData)}
                    onPointerUpFn={handleEndDrag}
                    name={itemData.name}
                    courseName={itemData.className}
                    complete={itemData.complete}
                    current={current}
                />
            )
        });

        return mappedData;
    }

    function mapCustomAssessments(data, current, indexes) {
        const mappedData = data.map((itemData, itemIndex) => {
            const taskIndex = indexes[itemIndex];
            return (
                <CustomCalendarItem
                    key={itemIndex}
                    keyData={test + itemIndex}
                    onPointerDownFn={(event) => startCustomTaskDrag(event, taskIndex)}
                    onPointerUpFn={handleEndCustomTaskDrag}
                    name={itemData.name}
                    courseName={itemData.class}
                    complete={itemData.complete}
                    comments={itemData.comments}
                    current={current}
                    clickTask={clickTask}
                    taskIndex={taskIndex}
                    setEditIndex={setEditIndex}
                />
            )
        });

        return mappedData;
    }

    function mapDays(daysData, current, useObserver) {

        // data are the days, they are javascript date objects
        const mappedDays = daysData.map((data, index) => {
            const itemsThatAreDueData = [];
            const customItemsThatAreDueData = [];
            const customItemsIndexes = [];
            for (let i = 0; i < assessmentData.length; i++) {
                if (isSameDay(data, assessmentData[i].dueDate)) {
                    itemsThatAreDueData.push(assessmentData[i]);
                }
            }

            // i is the index of the task in taskData, in other words, i is taskIndex
            for (let i = 0; i < taskData.length; i++) {
                const numberOfMS = taskData[i].date.seconds;
                const numberOfSeconds = numberOfMS * 1000;
                const taskDueDate = new Date(numberOfSeconds);
                if (isSameDay(data, taskDueDate)) {
                    customItemsThatAreDueData.push(taskData[i]);
                    customItemsIndexes.push(i);
                }
            }

            const itemsThatAreDue = mapAssessments(itemsThatAreDueData, current);
            const customItemsThatAreDue = mapCustomAssessments(customItemsThatAreDueData, current, customItemsIndexes);
            for (let i = 0; i < customItemsThatAreDue.length; i++) {
                itemsThatAreDue.push(customItemsThatAreDue[i]);
            }

            const currentDayColour = current ? "bg-red-500" : "bg-[#ef444480]";
            const textColour = current ? "" : "text-[#ffffff50]";
            const classNameText = `${isSameDay(data, startOfMonth(data)) && !isSameDay(currentMonth, data) && viewportWidth >= 640 ? "w-[65px]" : "w-[20px] xs:w-[32px] sm:w-[37px]"} text-sm xs:text-base sm:text-xl mx-auto mdplus:mx-0 text-center rounded-full xs:p-1 ${textColour} ${isSameDay(today, data) ? `${currentDayColour} font-semibold` : ""}`
            
            const borderColour = current ? "border-[#ffffff17]" : "border-[#ffffff08]";
            const classNameDiv = `border ${borderColour} min-h-[60px] xs:min-h-[80px] md:min-h-[100px] lg:min-h-[150px] py-1.5 px-0.5 xs:p-2 text-xl font-light lg:flex lg:flex-col bg-[#121220] hover:bg-[#121228]`
            const firstStr = isSameDay(data, startOfMonth(data)) && !isSameDay(currentMonth, data) && viewportWidth >= 640 ? format(data, 'MMM d') : format(data, 'd');
            if ((daysData.length === index + 1) && (additionalWeeks === 0 || useObserver)) {
                return (
                    <div 
                        className={classNameDiv}
                        key={index}
                        ref={lastDayRef}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClickDays(e, data);
                        }}
                        name="calendarDay"
                    >
                        <p className={classNameText}>
                            {firstStr}
                        </p>
     
                        <div className="lg:flex lg:flex-col grid sm:grid-cols-3 grid-cols-2 relative left-[2px]" 
                            onClick={(e) => {
                                e.stopPropagation();
                                if (viewportWidth < 1024) {
                                    handleClickDays(e, data);
                                }
                            }}
                        >
                            {itemsThatAreDue}
                        </div>
    
                    </div>
                )
            }
            else {
                return (
                    <div 
                        className={classNameDiv}
                        key={index}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClickDays(e, data);
                        }}
                        name="calendarDay"
                        onPointerEnter={() => {
                            if (viewportWidth >= 1024) {
                                setHoverDay(data)
                            }
                        }}
                        onPointerLeave={() => {
                            if (viewportWidth >= 1024) {
                                setHoverDay(null)
                            }
                        }}
                    >
                        <div className="flex flex-row justify-between">
                            <p className={classNameText}>
                                {firstStr}
                            </p>

                            {/* plus button */}
                            {isSameDay(data, hoverDay) && <div 
                                className="relative h-full aspect-square bg-[#121c22] rounded-xl shadow-sm shadow-black hover:cursor-pointer"
                                onClick={() => setShowAddCustomTask(data)}
                            >
                                <div className="h-full">
                                    <div className="bg-[#aaaaaa] w-[80%] left-[10%] h-[10%] top-[45%] relative rounded-full" />
                                    <div className="bg-[#aaaaaa] w-[10%] left-[45%] h-[80%] relative rounded-full" />
                                </div>
                            </div>}
                            {/*  end of the plus button  */}

                        </div>
                        
                        <div className="lg:flex lg:flex-col grid sm:grid-cols-3 grid-cols-2" 
                            onClick={(e) => {
                                e.stopPropagation();
                                if (viewportWidth < 1024) {
                                    handleClickDays(e, data);
                                }
                            }}
                        >
                            {itemsThatAreDue}
                        </div>
    
                    </div>
                )
            }
        });

        return mappedDays;
    }
    

    function handleClickDays(event, day) {
        const name = event.target.getAttribute('name');
        if (name === 'calendarDay' && viewportWidth >= 1024 && menuDay === false) {
            setMenuDay(day);
        }
        else if (viewportWidth < 1024 && menuDay === false) {
            setMenuDay(day);
        }
    }

    function handleNextDay() {
        setMenuDay(prev => {
            const nextDay = add(prev, { days: 1 });
            return nextDay;
        });
    }

    function handleLastDay() {
        setMenuDay(prev => {
            const lastDay = sub(prev, { days: 1 });
            return lastDay;
        });
    }

    const days = mapDays(allDaysInCurrentMonth, true, false);

    const firstWeekDays = mapDays(allDaysInFirstWeek, false, false);
    
    const lastWeekDays = mapDays(allDaysInLastWeek, false, false);

    const additionalDays = mapDays(allAdditionalDays, false, true);

    function handleMouseLeave() {
        if (draggedItem) {
            assignDate(draggedItem.dueDate);
            setTest(prev => prev - 1);
            setDraggedItem(null);
        }
    }

    function startDrag(event, itemData) {
        setDraggedItem(itemData);
        const xPos = event.clientX;
        const yPos = event.clientY;
        setStartingCoords([xPos, yPos]);
    }

    function startCustomTaskDrag(event, index) {
        setDraggedIndex(index);
        const xPos = event.clientX;
        const yPos = event.clientY;
        setStartingCoords([xPos, yPos]);
    }

    function handleEndDrag(event) {
        const xPos = event.clientX;
        const yPos = event.clientY;

        const name = event.target.getAttribute('name');
        if (name === 'completeButton' && (xPos === startingCoords[0] && yPos === startingCoords[1])) {
            handleCompleteClick(draggedItem.courseIndex, draggedItem.sectionIndex, draggedItem.dataIndex);
        }

        if (xPos === startingCoords[0] && yPos === startingCoords[1]) {
            return ;
        }

        if (draggedItem) {
            const calendarElem = document.getElementById('calendar');
            const calendarDaysElem = calendarElem.childNodes;
            const heights = []
            for (let i = 0; i < calendarDaysElem.length; i++) {
                const currentHeight = calendarDaysElem[i].offsetHeight;
                if (Math.floor(i/7) === i/7) {
                    heights.push(currentHeight);
                }
            }
            
            const { left, right, top, bottom } = calendarElem.getBoundingClientRect();
            
            const widthOfCalendar = right - left;
    
            const totalNumberOfDays = Number(allDaysInCurrentMonth.length) + Number(allDaysInFirstWeek.length) + Number(allDaysInLastWeek.length) + Number(allAdditionalDays.length);
            const numOfRows = totalNumberOfDays / 7;
    
            const colWidth = Number(widthOfCalendar) / 7;
    
            const colIndex = Math.floor((xPos - left)/colWidth);   
            let rowIndex = 0;
            if (yPos < top || yPos > bottom) {
                rowIndex = -1;
            }
            else {
                let currentYPos = yPos - top;
                for (let i = 0; i < heights.length; i++) {
                    if (currentYPos <= heights[i]) {
                        rowIndex = i;
                        break;
                    }
                    currentYPos -= heights[i];
                }
            }
    
            const arrayIndex = (rowIndex >= 0 && 
                rowIndex <= (numOfRows - 1) && 
                colIndex >= 0 && 
                colIndex <= 6 && 
                draggedItem !== null
            ) ? colIndex + 7 * rowIndex : false;
            
            const allDaysDisplayed = allDaysInFirstWeek.concat(allDaysInCurrentMonth).concat(allDaysInLastWeek).concat(allAdditionalDays);

            if (arrayIndex !== false) {
                assignDate(allDaysDisplayed[arrayIndex]);
            }
            else {
                assignDate(draggedItem.dueDate);
            }
        }

        setTest(prevTest => prevTest + 1);
        setDraggedItem(null);
    }

    function handleEndCustomTaskDrag(event) {
        const xPos = event.clientX;
        const yPos = event.clientY;

        const name = event.target.getAttribute('name');
        if (name === 'completeButton' && (xPos === startingCoords[0] && yPos === startingCoords[1])) {

            // this needs to modified to a custom task version
            handleCompleteClickForTasks(draggedIndex);
        }

        if (xPos === startingCoords[0] && yPos === startingCoords[1]) {
            return ;
        }

        if (draggedIndex !== undefined) {
            const calendarElem = document.getElementById('calendar');
            const calendarDaysElem = calendarElem.childNodes;
            const heights = []
            for (let i = 0; i < calendarDaysElem.length; i++) {
                const currentHeight = calendarDaysElem[i].offsetHeight;
                if (Math.floor(i/7) === i/7) {
                    heights.push(currentHeight);
                }
            }
            
            const { left, right, top, bottom } = calendarElem.getBoundingClientRect();
            
            const widthOfCalendar = right - left;
    
            const totalNumberOfDays = Number(allDaysInCurrentMonth.length) + Number(allDaysInFirstWeek.length) + Number(allDaysInLastWeek.length) + Number(allAdditionalDays.length);
            const numOfRows = totalNumberOfDays / 7;
    
            const colWidth = Number(widthOfCalendar) / 7;
    
            const colIndex = Math.floor((xPos - left)/colWidth);   
            let rowIndex = 0;
            if (yPos < top || yPos > bottom) {
                rowIndex = -1;
            }
            else {
                let currentYPos = yPos - top;
                for (let i = 0; i < heights.length; i++) {
                    if (currentYPos <= heights[i]) {
                        rowIndex = i;
                        break;
                    }
                    currentYPos -= heights[i];
                }
            }
    
            const arrayIndex = (rowIndex >= 0 && 
                rowIndex <= (numOfRows - 1) && 
                colIndex >= 0 && 
                colIndex <= 6 && 
                draggedIndex !== null
            ) ? colIndex + 7 * rowIndex : false;
            
            const allDaysDisplayed = allDaysInFirstWeek.concat(allDaysInCurrentMonth).concat(allDaysInLastWeek).concat(allAdditionalDays);

            if (arrayIndex !== false) {
                assignDateToCustomTask(allDaysDisplayed[arrayIndex]);
            }
            else {
                const oldDate = new Date(taskData[draggedIndex].date.seconds * 1000);
                assignDateToCustomTask(oldDate);
            }
        }

        setTest(prevTest => prevTest + 1);
        setDraggedIndex(null);
    }

    function assignDate(date) {
        const newDate = Timestamp.fromDate(date);
        setCourseData(prevData => {
            let newData = JSON.parse(JSON.stringify(prevData));
            const courseIndex = draggedItem.courseIndex;
            const sectionIndex = draggedItem.sectionIndex;
            const dataIndex = draggedItem.dataIndex;
            newData[courseIndex].sections[sectionIndex].data[dataIndex].dueDate = newDate;

            if (!isSameDay(date, draggedItem.dueDate)) {
                updateDB(newData);
                const tempAssessmentData = getAssessmentDataArray(newData);
                setAssessmentData(tempAssessmentData);
            }

            return newData;
        });
    }

    function assignDateToCustomTask(date) {
        const taskIndex = draggedIndex; 
        const newDate = Timestamp.fromDate(date);
        const oldDate = new Date(taskData[taskIndex].date.seconds * 1000);

        setTaskData(prevTaskData => {
            let newTaskData = [...prevTaskData];
            newTaskData[taskIndex].date = newDate;

            if (!isSameDay(date, oldDate)) {
                updateTasksDB(newTaskData);
            }

            return newTaskData;

        });
    }

    function handleCompleteClick(courseIndex, sectionIndex, dataIndex) {
        setComplete(prevComplete => {
            const newCompleteStatus = prevComplete[courseIndex][sectionIndex][dataIndex] !== (true || false) ? true : !prevComplete[courseIndex][sectionIndex][dataIndex];

            const newState = {
                ...prevComplete,
                [courseIndex]: {
                    ...prevComplete[courseIndex],
                    [sectionIndex]: {
                        ...prevComplete[sectionIndex],
                        [dataIndex]: newCompleteStatus
                    }
                }
            }

            setCourseData(prevData => {
                let newCourseData = JSON.parse(JSON.stringify(prevData));
                newCourseData[courseIndex].sections[sectionIndex].data[dataIndex].complete = newCompleteStatus;

                const tempAssessmentData = getAssessmentDataArray(newCourseData);
                setAssessmentData(tempAssessmentData);

                updateDB(newCourseData);
                return newCourseData;
            });

            return newState;
        });
    }

    function handleCompleteClickForTasks(taskIndex) {
        setTaskData(prevTaskData => {
            const newTaskData = [...prevTaskData];
            newTaskData[taskIndex].complete = !prevTaskData[taskIndex].complete;

            updateTasksDB(newTaskData);

            return newTaskData;
        })
    }

    function createTask() {
        setTaskData(prevTaskData => {
            const newTaskData = [...prevTaskData];
            setTaskInfo(prevTaskInfo => {
                let newTaskInfo = {
                    ...prevTaskInfo,
                    date: Timestamp.fromDate(showAddCustomTask)
                }

                if (prevTaskInfo.class === "") {
                    newTaskInfo.class = "Untitled";
                }
                if (prevTaskInfo.name === "") {
                    newTaskInfo.name = "Untitled";
                }

                newTaskData.push(newTaskInfo);
                updateTasksDB(newTaskData);

                return newTaskInfo;
            });

            return newTaskData;
        });
    }

    function changeTask(e) {
        const { name, value } = e.target;
        const nameRegex = /^[0-9a-z "().,?!;:\[\]\/'_-]*$/i;
        if (nameRegex.test(value) && name !== "comments" && value.length < 48) {
            setTaskInfo(prev => {
                const newTaskInfo = {
                    ...prev,
                    [name]: value,
                }

                return newTaskInfo;
            });
        }
        else if (nameRegex.test(value) && name === "comments" && value.length < 200) {
            setTaskInfo(prev => {
                const newTaskInfo = {
                    ...prev,
                    [name]: value,
                }

                return newTaskInfo;
            });
        }
    }

    function clickTask(event, index) {
        const name = event.target.getAttribute('name');
        if (name === 'completeButton') {
            return;
        }
        const day = new Date(taskData[index].date.seconds * 1000);
        setShowEditCustomTask(day);
        setTaskInfo(taskData[index]);
    }

    function updateTask() {
        setTaskData(prevTaskData => {
            const newTaskData = [...prevTaskData];
            newTaskData[editIndex] = taskInfo;

            updateTasksDB(newTaskData);

            return newTaskData;
        });

        setShowEditCustomTask(false);
    }

    function deleteTask() {
        setTaskData(prevTaskData => {
            const newTaskData = [...prevTaskData];
            newTaskData.splice(editIndex, 1);

            updateTasksDB(newTaskData);

            return newTaskData;
        });

        setShowEditCustomTask(false);
    }

    async function updateDB(newData) {
        const courseDocRef = doc(db, "users", userID);
        await updateDoc(courseDocRef, {
            courses: newData
        });
    }

    async function updateTasksDB(newData) {
        const courseDocRef = doc(db, "users", userID);
        await updateDoc(courseDocRef, {
            tasks: newData
        });
    }

    return (
        <div className="overflow-hidden">
           
            <DashboardNavbar />    

            {menuDay != false && (
                <MobileCalendarMenu 
                    day={menuDay}
                    nextDay={handleNextDay}
                    lastDay={handleLastDay}
                    data={assessmentData}
                    taskData={taskData}
                    handleCompleteClick={handleCompleteClick}
                    handleCompleteClickForTasks={handleCompleteClickForTasks}
                    menuDay={menuDay}
                    setMenuDay={setMenuDay}
                    setShowAddCustomTask={setShowAddCustomTask}
                    // setChildFn={setChildHandleCloseFunction}
                />
            )}

            {showAddCustomTask && (
                <CreateTaskMenu 
                    setShowAddCustomTask={setShowAddCustomTask}
                    day={showAddCustomTask}
                    createTask={createTask}
                    changeTask={changeTask}
                    taskInfo={taskInfo}
                />
            )}

            {showEditCustomTask && (
                <EditTaskMenu 
                    setShowEditCustomTask={setShowEditCustomTask}
                    day={showEditCustomTask}
                    updateTask={updateTask}
                    changeTask={changeTask}
                    taskInfo={taskInfo}
                    setEditIndex={setEditIndex}
                    deleteTask={deleteTask}
                />
            )

            }

            <div 
                className="relative top-[75px] text-white font-montserrat bg-[#121220] w-full min-h-[calc(100vh-75px)]"
                onMouseLeave={handleMouseLeave}
            >
                <div className="w-4/5 mx-auto pt-16 pb-32">

                    {/* CALENDAR HEADER STARTS HERE */}
                    <div className="flex flex-row items-center justify-between gap-8">
                        <h1 className="text-2xl xs:text-[1.75rem] sm:text-4xl font-bold">{header}</h1>
                        <div className="flex flex-col xs:flex-row justify-between items-center gap-4 sm:gap-10">
                            <motion.button
                                className="p-1 px-4 sm:px-6 bg-red-500 sm:font-semibold rounded-xl text-xs sm:text-base"
                                initial={{
                                    scale: 1.0,
                                }}
                                whileHover={{
                                    scale: 1.05,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.95,
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={handleGoToToday}
                            >
                                TODAY
                            </motion.button>
                            <div className="flex flex-row items-center justify-between w-[50px] sm:w-[70px]">
                                <motion.img
                                    src="/images/lastMonthButton.png"
                                    alt=""
                                    className="sm:w-[20px] w-[15px]"
                                    initial={{
                                        opacity: 0.5,
                                        scale: 1.0
                                    }}
                                    whileHover={{
                                        opacity: 1.0,
                                        scale: 1.15,
                                        transition: {
                                            duration: 0.25
                                        }
                                    }}
                                    whileTap={{
                                        opacity: 1.0,
                                        scale: 0.85,
                                        transition: {
                                            duration: 0.125
                                        }
                                    }}
                                    onClick={handleLastMonth}
                                />
                                <motion.img
                                    src="/images/nextMonthButton.png"
                                    alt=""
                                    className="sm:w-[20px] w-[15px]"
                                    initial={{
                                        opacity: 0.5,
                                        scale: 1.0
                                    }}
                                    whileHover={{
                                        opacity: 1.0,
                                        scale: 1.15,
                                        transition: {
                                            duration: 0.25
                                        }
                                    }}
                                    whileTap={{
                                        opacity: 1.0,
                                        scale: 0.85,
                                        transition: {
                                            duration: 0.125
                                        }
                                    }}
                                    onClick={handleNextMonth}
                                />
                            </div>
                        </div>
                    </div>
                    {/* CALENDAR HEADER ENDS HERE */}


                    {/* CALENDER STARTS HERE */}
                    <div className="border-b w-full mt-8 grid grid-cols-7 py-2 font-montserrat text-lg sm:text-xl font-semibold">
                        <p className="text-center">S</p>
                        <p className="text-center">M</p>
                        <p className="text-center">T</p>
                        <p className="text-center">W</p>
                        <p className="text-center">T</p>
                        <p className="text-center">F</p>
                        <p className="text-center">S</p>
                    </div>
                    <div   
                        className="w-full grid grid-cols-7 mb-32"
                        id="calendar"
                        // ref={calendarRef}         // againt not needed if we are not using dragConstraints
                    >
                        {firstWeekDays}
                        {days}
                        {lastWeekDays}
                        {additionalWeeks && additionalDays}
                    </div>
                    {/* CALENDER ENDS HERE */}

                </div>
            </div>
        </div>
    )
}