import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function NavbarLink({ to, text }) {
    return (
        <motion.button
            initial={{ scale: 1.0 }}
            whileHover={{
                scale: 1.15,
                transition: { duration: 0.25 }
            }}
            whileTap={{ 
                scale: 0.9,
                transition: { duration: 0.125 }
            }}
        >
            {to ? (<Link to={to}>
                <p 
                    className="bg-gradient-to-r from-[#aaaaaa] via-[#dddddd] to-[#aaaaaa] text-transparent bg-clip-text text-xl w-fit" 
                >
                    {text}
                </p>
            </Link>) : (
                <p 
                className="bg-gradient-to-r from-[#aaaaaa] via-[#dddddd] to-[#aaaaaa] text-transparent bg-clip-text text-xl w-fit" 
            >
                {text}
            </p>)} 
        </motion.button>
    )
}