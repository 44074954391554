import React from "react";

export default function MobileTaskItem({ data, handleCompleteClick, index }) {
    return (
        <div className="bg-[#1b1b1b] flex flex-row justify-between items-center py-2 border-y border-y-[#ffffff50]">
            <div className="flex flex-col gap-2">
                <p className="font-dosis uppercase tracking-widest xs:font-bold font-semibold text-base xs:text-lg sm:text-xl truncate md:max-w-[480px] sm:max-w-[350px] xs:max-w-[200px] max-w-[140px]">{data.name}</p>
                <p className="font-montserrat uppercase text-base xs:text-lg sm:text-xl truncate md:max-w-[480px] sm:max-w-[350px] xs:max-w-[200px] max-w-[140px]">{data.class}</p>
            </div>
            <div className="flex flex-row items-center gap-3 sm:gap-4">
                <p 
                    className="font-montserrat text-base xs:text-lg sm:text-xl hover:cursor-pointer"
                    onClick={() => handleCompleteClick(index)}
                >Complete</p>
                <img 
                    src={data.complete ? "/images/checkedBox.svg" : "/images/uncheckedBox.svg"}
                    alt=""
                    className="w-[25px] sm:w-[30px] hover:cursor-pointer"
                    name="completeButton"
                    onClick={() => {
                        handleCompleteClick(index);
                    }}
                />
            </div>
        </div>
    )
}