import React, { useState, useEffect } from "react";

export default function Tip({ text, x, y, type }) {

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    let styleOBJ = {
        position: "fixed",
        top: y + 20,
        left: x + 20
    }

    if (type && type.toUpperCase() === "LEFT") {
        styleOBJ = {
            position: "fixed",
            top: y + 20,
            right: viewportWidth - x
        }
    }
    return (
        <div 
            className="bg-black/70 max-w-[200px] border py-2 px-4 z-50 rounded-2xl"
            style={styleOBJ}
        >
            <p className="font-montserrat text-white text-sm text-center mx-auto normal-case font-normal tracking-widest">{text}</p>
        </div>
    )
}