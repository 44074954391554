import { useEffect } from "react";

/**
 * 
 * @param {boolean} disable         true if you want to disable 'pull down to refresh' on mobile screens
 *                                  false if you want to keep the feature  
 */
export default function useDisablePullToRefresh(disable) {
    useEffect(() => {
        if (!disable) return;

        const preventPullToRefresh = (e) => {
            if (e.touches.length !== 1) return;
            if (e.touches[0].clientY < 5) {
                e.preventDefault();
            }
        };

        document.addEventListener('touchstart', preventPullToRefresh, { passive: false });
        document.addEventListener('touchmove', preventPullToRefresh, { passive: false });

        return () => {
            document.removeEventListener('touchstart', preventPullToRefresh);
            document.removeEventListener('touchmove', preventPullToRefresh);
        };
    }, [disable]);
}