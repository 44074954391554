import React, { useEffect } from "react";
import { motion, useAnimate, useDragControls, useMotionValue } from "framer-motion";
import { format, isSameDay } from "date-fns";
import MobileCalendarItem from "./MobileCalendarItem";
import "../mobileScrollbar.css";
import MobileTaskItem from "./MobileTaskItem";

export default function MobileCalendarMenu({ day, nextDay, lastDay, data, taskData, handleCompleteClick, handleCompleteClickForTasks, setMenuDay, setShowAddCustomTask }) {
    const controls = useDragControls();
    const y = useMotionValue(0);
    const [scope, animate] = useAnimate();
    const [bg, animateBg] = useAnimate();

    const handleKeyDown = (event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            handleClose();
        }
    };

    async function handleClose() {
        animate(scope.current, {
            opacity: [1, 0]
        });
        animateBg(bg.current, { background: "rgba(0, 0, 0, 0)" });

        const yStart = typeof y.get() === "number" ? y.get() : 0;
        const outerDiv = document.getElementById('mobileMenuOuterDiv');
        let height = 0;
        if (outerDiv) {
            height = outerDiv.getBoundingClientRect().height;
        }

        await animate(scope.current, {
            y: [yStart, height],
        });
        setMenuDay(false);
    }
    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const itemsThatAreDueData = [];
    for (let i = 0; i < data.length; i++) {
        if (isSameDay(day, data[i].dueDate)) {
            itemsThatAreDueData.push(data[i]);
        }
    }

    let assessments = itemsThatAreDueData.map((assessment, index) => {
        return (
            <MobileCalendarItem 
                data={assessment}
                handleCompleteClick={handleCompleteClick}
                key={index}
            />
        )
    });

    const tasksThatAreDueData = [];
    const customIndexes = [];

    // i is the task index
    for (let i = 0; i < taskData.length; i++) {
        const dueDate = new Date(taskData[i].date.seconds * 1000);
        if (isSameDay(day, dueDate)) {
            tasksThatAreDueData.push(taskData[i]);
            customIndexes.push(i);
        }
    }

    const tasks = tasksThatAreDueData.map((itemData, itemIndex) => {
        const taskIndex = customIndexes[itemIndex];
        return (
            <MobileTaskItem 
                data={itemData}
                handleCompleteClick={handleCompleteClickForTasks}
                index={taskIndex}
                key={taskIndex}
            />
        )
    });

    let allItems = assessments.concat(tasks);

    if (allItems.length === 0) {
        allItems = <div>No tasks to display.</div>
    }

    return (
        <motion.div 
            className="fixed font-montserrat text-white z-40 bottom-0 w-full h-screen overflow-hidden"
            initial={{ background: "rgba(0, 0, 0, 0)" }}
            animate={{ background: "rgba(0, 0, 0, 0.7)" }}
            ref={bg}
            onClick={(e) => {
                e.stopPropagation();
                const height = window.innerHeight * 0.4;
                if (e.clientY < height) {
                    handleClose();
                }
            }}
        >
            <motion.div 
                ref={scope}
                className="rounded-t-3xl bg-[#1b1b1b] absolute bottom-0 w-full h-[60vh] z-50"
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                style={{ y }}
                transition={{
                    ease: [0.1, 0.9, 0, 1],
                    duration: 1
                }}
                drag="y"
                dragControls={controls}
                dragListener={false}
                dragConstraints={{
                    top: 0,
                    bottom: 0
                }}
                dragElastic={{
                    top: 0,
                    bottom: 0.5
                }}
                onDragEnd={() => {
                    if (y.get() >= 69) {
                        handleClose();
                    }
                }}
                id="mobileMenuOuterDiv"
            >

                <div className="relative h-full">
                    <button 
                        className="absolute left-[calc((50vw)-25px)] top-[-50px] w-[50px] bg-[url('/public/images/bar.svg')] opacity-30 hover:opacity-80 h-[50px] bg-cover cursor-grab touch-none active:cursor-grabbing z-40"
                        onPointerDown={(e) => {
                            controls.start(e);
                        }}
                    >    
                    </button>

                    <div 
                        className="overflow-scroll h-full mt-12 touch-pan-y" 
                        id="mobileMenu"
                    >
                        <div className="w-[85%] mx-auto">
                            <div className="flex flex-row justify-between items-center mb-8 pt-2 xs:pt-4 sm:pt-8">
                                <p className="font-semibold text-xl xs:text-2xl sm:text-3xl w-[70%] xs:w-[80%]">
                                    <span className="font-light">Tasks for </span>
                                    {format(day, "eeee, LLLL do, yyyy")}
                                </p>
                                <div className="flex flex-row justify-around items-center gap-8">
                                    <motion.img
                                    src="/images/lastMonthButton.png"
                                    alt=""
                                    className="w-[25px] h-[25px] ml-8"
                                    onClick={lastDay}
                                    initial={{ 
                                        scale: 1.0,
                                        opacity: 0.5    
                                    }}
                                    whileHover={{
                                        scale: 1.15,
                                        opacity: 1.0,
                                        transition: { 
                                        duration: 0.25 
                                        }
                                    }}
                                    whileTap={{
                                        scale: 0.85,
                                        opacity: 1.0,
                                        transition: {
                                        duration: 0.125
                                        }
                                    }}
                                    />
                                    <motion.img
                                    src="/images/nextMonthButton.png"
                                    alt=""
                                    className="w-[25px] h-[25px]"
                                    onClick={nextDay}
                                    initial={{ 
                                        scale: 1.0,
                                        opacity: 0.5    
                                    }}
                                    whileHover={{
                                        scale: 1.15,
                                        opacity: 1.0,
                                        transition: { 
                                        duration: 0.25 
                                        }
                                    }}
                                    whileTap={{
                                        scale: 0.85,
                                        opacity: 1.0,
                                        transition: {
                                        duration: 0.125
                                        }
                                    }}
                                    />
                                </div>
                            </div>
                            <div className="border-collapse mb-8">
                                {allItems}
                            </div>
                        </div>
                        <motion.div 
                            className="mx-auto w-fit flex flex-row gap-4 items-center mb-32 hover:cursor-pointer"
                            initial={{
                                scale: 1
                            }}
                            whileHover={{
                                scale: 1.05,
                                transition: {
                                    duration: 0.25
                                }
                            }}
                            whileTap={{
                                scale: 0.95,
                                transition: {
                                    duration: 0.125
                                }
                            }}
                            // onClick={() => ()}
                        >
                            <div 
                                className="relative w-[40px] sm:w-[50px] aspect-square bg-[#121c22] rounded-xl shadow-sm shadow-black"
                                onClick={() => setShowAddCustomTask(day)}
                            >
                                <div className="h-full">
                                    <div className="bg-[#aaaaaa] w-[80%] left-[10%] h-[10%] top-[45%] relative rounded-full" />
                                    <div className="bg-[#aaaaaa] w-[10%] left-[45%] h-[80%] relative rounded-full" />
                                </div>
                            </div>
                            <p className="text-xl sm:text-2xl font-montserrat select-none">ADD A TASK</p>
                        </motion.div>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    )
}