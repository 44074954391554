import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import DashboardNavbar from "../components/DashboardNavbar";
import { getDoc, doc, updateDoc, setDoc } from "firebase/firestore";
import AddCourse from "../components/AddCourse";
import CourseCard from "../components/CourseCard";
import { AnimatePresence } from "framer-motion";
import Popup from "../components/Popup";
import LoadingSpinner from "../components/LoadingSpinner";

export default function Dashboard() {
    const [userID, setUserID] = useState(null);
    const [courseData, setCourseData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const auth = getAuth();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            // if the user is signed in 
            if (user) {
                const uid = user.uid;
                setUserID(uid);
                const docRef = doc(db, "users", user.uid);
                getDoc(docRef)
                    .then((docSnap) => {
                        if (docSnap.exists() && docSnap.data().welcome) {
                            navigate("/welcome");
                        }
                        if (docSnap.exists() && !docSnap.data().welcome) {
                            setCourseData(docSnap.data().courses);
                        }

                        // for when i need to add more booleans or whatever to each user but ive kinda already sent it out
                        // and theres already users so i just gotta add it here
                        if (docSnap.exists() && !Object.keys(docSnap.data()).includes("showTooltipMessage")) {
                            updateDBWithNewSht(docRef);
                        }

                        // update so each assessment has comments, only required if there is an assessment and it doesnt have 
                        // the comment property. assessments will either all have comment properties, or they all won't.
                        // if (docSnap.exists() && 
                        //     courseData[0] && 
                        //     courseData[0].sections &&
                        //     courseData[0].sections[0] &&
                        //     courseData[0].sections[0].data && 
                        //     courseData[0].sections[0].data[0] &&
                        //     courseData[0].sections[0].data[0].comments === undefined
                        // ) {
                        //     addCommentsToEachAssessment(docRef);
                        // }

                    }).catch((error) => {
                        navigate("/error");
                    }
                );

            }
                
            // if the user is NOT signed in
            else {
                navigate("/");
            }
        });
    }, []);

    // for when i need to add more booleans or whatever to each user but ive kinda already sent it out
    // and theres already users so i just gotta add it here
    async function updateDBWithNewSht(theDocRef) {
        try {
            await updateDoc(theDocRef, {
                showTooltipMessage: true
            });
        }  
        catch (error) {
            console.log(error);
        } 
    }

    // this function is similar to the above one except it modifies each assessment for each user
    // function addCommentsToEachAssessment(theDocRef) {
    //     setCourseData(prevCourseData => {
    //         const newCourseData = [...prevCourseData];

    //         // looping through each course
    //         // be wary of undefined properties
    //         for (let i = 0; i < prevCourseData.length; i++) {
    //             if (prevCourseData[i].sections) {
    //                 for (let j = 0; j < prevCourseData[i].sections.length; j++) {
    //                     if (prevCourseData[i].sections[j].data) {
    //                         for (let k = 0; k < prevCourseData[i].sections[j].data.length; k++) {
    //                             if (newCourseData[i].sections[j].data[k].comments === undefined) {
    //                                 newCourseData[i].sections[j].data[k].comments = "";
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         }

    //         updateDBWithEvenNewerSht(newCourseData, theDocRef);

    //         return newCourseData;
    //     });
    // }

    // async function updateDBWithEvenNewerSht(data, theDocRef) {
    //     try {
    //         updateDoc(theDocRef, {
    //             courses: data
    //         });
    //     }
    //     catch (error) {
    //         console.log(error);
    //     }
    // }


    sessionStorage.setItem('lastPage', JSON.stringify('dashboard'))

    let courses = [];
    if (courseData.length !== 0) {
        courses = courseData.map((data, index) => {
            if (!data.archived) {
                return (
                    <CourseCard 
                        index={index}
                        key={index}
                        name={data.name}
                        target={data.target}
                        avg={(Number(data.weightAchieved)/Number(data.weightPossible)*100).toFixed(2)}
                        weightAchieved={data.weightAchieved}
                        weightPossible={data.weightPossible}
                        handleArchiveClick={handleArchiveClick}
                        fromArchive={false}
                    />
                )
            }
        });
    }

    const [popup, setPopup] = useState({
        visible: false,
        text: "",
        image: ""
    });

    /**
     * 
     * @param {string} text 
     * @param {string} image 
     */
    function handlePopup(text, image) {
        if (!popup.visible) {
            setPopup(() => {
                return {
                    visible: true,
                    text: text,
                    image: image
                }
            });
            setTimeout(() => setPopup({
                visible: false,
                text: "",
                image: ""
            }), 3000);
        }
        else {
            setPopup(() => {
                return {
                    visible: true,
                    text: text,
                    image: image
                }
            });
        }
    }
    
    function handleArchiveClick(index) {
        setCourseData(prevData => {
            prevData[index].archived = true;

            handlePopup(`Successfully archived ${prevData[index].name}`, "/images/checkmark.png");

            updateDB(prevData);

            return prevData;
        });
    }

    async function updateDB(data) {
        const docRef = doc(db, "users", userID);
        await updateDoc(docRef, {
            courses: data
        });
    }

    return (
        <div className="font-montserrat relative">
            {isLoading && <LoadingSpinner />}
            <DashboardNavbar ignore="true" />
            <div className="relative top-[75px] text-white bg-[#121220] min-h-[calc(100vh-75px)]">
                <div className="w-4/5 mx-auto pt-16 grid grid-cols-1 mdplus:grid-cols-2 2xl:grid-cols-3 gap-8 xl:gap-16 2xl:gap-8 pb-64">
                    {courses}
                    <AddCourse index={courseData.length} setLoading={setIsLoading} />
                </div>
            </div>

            {/* POPUP WARNING SHTS */}
            <AnimatePresence>
            {popup.visible && (
                <Popup text={popup.text} image={popup.image} />
            )}
            </AnimatePresence>
        </div>
    )
}