// scale formats are: [GPA, fromRange, toRange]
const fourPointThreeScaleData = [
    [4.3, 90, 100.1],
    [4.0, 85, 90],
    [3.7, 80, 85],
    [3.3, 77, 80],
    [3.0, 73, 77],
    [2.7, 70, 73],
    [2.3, 67, 70],
    [2.0, 63, 67],
    [1.7, 60, 63],
    [1.3, 57, 60],
    [1.0, 53, 57],
    [0.7, 50, 53],
    [0, 0, 50]
];

const fourPointOhScaleDataI = [
    [4.0, 90, 100.1],
    [3.9, 85, 90],
    [3.7, 80, 85],
    [3.3, 77, 80],
    [3.0, 73, 77],
    [2.7, 70, 73],
    [2.3, 67, 70],
    [2.0, 63, 67],
    [1.7, 60, 63],
    [1.3, 57, 60],
    [1.0, 53, 57],
    [0.7, 50, 53],
    [0, 0, 50]
];

const fourPointOhScaleDataII = [
    [4.0, 97, 100.1],
    [4.0, 93, 97],
    [3.7, 90, 93],
    [3.3, 87, 90],
    [3.0, 83, 87],
    [2.7, 80, 83],
    [2.3, 77, 80],
    [2.0, 73, 77],
    [1.7, 70, 73],
    [1.3, 67, 70],
    [1.0, 65, 67],
    [1.0, 60, 65],
    [0, 0, 60],
];

const fourPointOhScaleDataIII = [
    [4.0, 90, 100.1],
    [3.0, 80, 90],
    [2.0, 70, 80],
    [1.0, 60, 70],
    [0.0, 0, 60]
];

export const scaleData = {
    0: {
        data: fourPointThreeScaleData,
        name: "4.3 SCALE"
    },
    1: {
        data: fourPointOhScaleDataI,
        name: "4.0 SCALE I"
    },
    2: {
        data: fourPointOhScaleDataII,
        name: "4.0 SCALE II"
    },
    3: {
        data: fourPointOhScaleDataIII,
        name: "4.0 SCALE III"
    }
}