import React, { useEffect, useState } from "react";
import { motion, useAnimate, AnimatePresence } from "framer-motion";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Popup from "./Popup";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, getAuth } from "firebase/auth";

export default function AddCourse({ index, setLoading }) {
    const [formData, setFormData] = useState({
        courseName: "",
        credits: ""
    });
    const [opaque, setOpaque] = useState(false);
    const [courseCodeData, setCourseCodeData] = useState(null);
    const [, setCourseData] = useState(null);
    const [userID, setUserID] = useState(null);
    const [scope, animate] = useAnimate();
    const [paste, setPaste] = useState(false);
    const [code, setCode] = useState(null);
    const navigate = useNavigate();
    let menu = false;

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const auth = getAuth();
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            // if the user is signed in 
            if (user) {
                const uid = user.uid;
                setUserID(uid);
                const docRef = doc(db, "users", user.uid);
                getDoc(docRef)
                    .then((docSnap) => {
                        if (docSnap.exists() && docSnap.data().welcome) {
                            navigate("/welcome");
                        }
                        if (docSnap.exists() && !docSnap.data().welcome) {
                            setCourseData(docSnap.data().courses);
                        }
                    }).catch((error) => {
                        navigate("/error");
                    }
                );

                const codeDocRef = doc(db, "general", "shareCodes");
                getDoc(codeDocRef)
                    .then((docSnap) => {
                        if (docSnap.exists()) {
                            setCourseCodeData(docSnap.data());
                        }
                        else {
                            navigate("/error");
                        }
                    }).catch((error) => {
                        navigate("/error");
                    }
                );

            //     const courseDocRef = doc(db, "users", uid);
            //     getDoc(courseDocRef)
            //         .then((docSnap) => {
            //             if (docSnap.exists()) {
            //                 setCourseData(docSnap.data().courses);
            //             }
            //             else {
            //                 navigate("/error");
            //             }
            //         }).catch((error) => {
            //             navigate("/error");
            //         }
            //     );
            }
                
            // if the user is NOT signed in
            else {
                navigate("/");
            }
        });
    }, []);

    function handleClick() {
        setOpaque(true);
        menu = true;
        handleAnimate();
    }

    function handleBack() {
        setOpaque(false);
        setPaste(false);
        menu = false;
        handleAnimate();
    }

    async function handleAnimate() {
        if (menu && viewportWidth > 640) {
            await animate(scope.current, { y: -300 });
        }
        else if (menu && viewportWidth <= 640) {
            await animate(scope.current, { y: -265 });
        }
        else {
            await animate(scope.current, { y: 0 });
        }
    }

    function handleChange(event) {
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        });
    }

    const [popup, setPopup] = useState({
        visible: false,
        text: "",
        image: ""
    });

    /**
     * 
     * @param {string} text 
     * @param {string} image 
     */
    function handlePopup(text, image) {
        if (!popup.visible) {
            setPopup(() => {
                return {
                    visible: true,
                    text: text,
                    image: image
                }
            });
            setTimeout(() => setPopup({
                visible: false,
                text: "",
                image: ""
            }), 3000);
        }
        else {
            setPopup(() => {
                return {
                    visible: true,
                    text: text,
                    image: image
                }
            });
        }
    }

    function handleSubmit() {
        
        if (!paste) {
            const courseRegex = /^[a-z0-9 ]+$/i;
            if (formData.courseName.length > 10) {
                handlePopup("Course name cannot be more than 10 characters", "/images/warning.png");
            }
            else if (formData.courseName.length === 0) {
                handlePopup("Course name cannot be empty", "/images/warning.png");
            }
            else if (!courseRegex.test(formData.courseName)) {
                handlePopup("Course name can only contain letters and numbers", "/images/warning.png");
            }
            else if (isNaN(Number(formData.credits))) {
                handlePopup("Credits must be a number", "/images/warning.png");
            }
            else if (Number(formData.credits) === 0) {
                handlePopup("Credits may not be 0", "/images/warning.png");
            }
            else {
                setCourseData(prevCourseData => {
                    let newCourseData = JSON.parse(JSON.stringify(prevCourseData));
                    const emptyCourse = {
                        name: formData.courseName,
                        target: null,
                        avg: null,
                        weightAchieved: null,
                        weightPossible: null,
                        credits: Number(formData.credits),
                        sections: null,
                        archived: false
                    }
                    newCourseData.push(emptyCourse);
                    
                    updateDB(newCourseData);
                    
                    // clearFields();
                    
                    return newCourseData
                });
            }
        }
        else {
            const allCourseCodes = courseCodeData ? Object.keys(courseCodeData) : [];
            if (allCourseCodes.includes(code)) {
                const copiedCourseData = courseCodeData[code];
                setCourseData(prevCourseData => {
                    let newCourseData = JSON.parse(JSON.stringify(prevCourseData));
                    newCourseData.push(copiedCourseData);
                    
                    updateDB(newCourseData);
    
                    // clearFields();
    
                    return newCourseData
                });
            }
            else {
                handlePopup("Invalid code entered", "/images/warning.png");
            }
        }
    }
            
    function handleCodeChange(e) {
        const { value } = e.target;
        setCode(prev => {
            const codeRegex = /^[a-z0-9]*$/i;
            if (codeRegex.test(value) && value.length <= 15) {
                return value;
            }
            return prev;
        });
    }

    async function updateDB(data) {
        setLoading(true);
        const docRef = doc(db, "users", userID);
        await updateDoc(docRef, {
            courses: data
        });
        navigate(`/dashboard/${index}`);
        setLoading(false);
    }

    // function clearFields() {
    //     menu = false;
    //     setFormData(
    //         {
    //             courseName: "",
    //             credits: ""
    //         }
    //     );
    //     setCode(null);
    //     setOpaque(false);
    //     handleAnimate();
    // }

    function onCodeButtonClick() {
        setPaste(true);
        setFormData(
            {
                courseName: "",
                credits: ""
            }
        );
    }

    function onTextButtonClick() {
        setPaste(false);
        setCode(null);
    }

    return (
        <div>
            <motion.div 
                className={`border border-8 h-56 sm:h-64 font-montserrat overflow-hidden`}
                animate={{ opacity: opaque ? 0.8 : 0.2 }}
                whileHover={{ 
                    opacity: 0.8,
                    transition: { duration: 0.25 }
                }}
            >
                <motion.div 
                    className="flex flex-col justify-between items-center xs:w-2/3 w-[85%] mx-auto h-[450px] sm:h-[556px]"
                    ref={scope}
                >
                    <div className="flex flex-col justify-around items-center sm:h-64 h-52">
                        <motion.img 
                            className="w-[220px] sm:w-[250px] hover:cursor-pointer"
                            src="/images/addButton.png"
                            alt=""
                            onClick={handleClick}
                            initial={{ scale: 1 }}
                            whileHover={{ 
                                scale: 1.05,
                                transition: {
                                    duration: 0.25
                                }
                            }}
                            whileTap={{
                                scale: 0.95, 
                                transition: {
                                    duration: 0.125
                                }
                            }}
                        />
                        <p className="font-semibold text-xl">
                            ADD NEW COURSE
                        </p>
                    </div>
                    <div className="h-48 sm:h-64 w-full">
                        {!paste ? (<div className="flex flex-row justify-between items-end gap-4">
                            <input 
                                type="text"
                                placeholder="Course Name"
                                onChange={handleChange}
                                name="courseName"
                                value={formData.courseName}
                                autoComplete="off"
                                className="bg-[#121220] border-b border-[#aaaaaa80] focus:outline-none focus:border-b-[#cccccc] text-xl w-full py-1 mt-6"
                            />
                            <motion.button 
                                className="bg-[url('/public/images/pasteIcon.png')] bg-cover w-[35px] h-[30px]"
                                initial={{ scale: 1, opacity: 0.5 }}
                                whileHover={{
                                    scale: 1.1,
                                    opacity: 1,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.9,
                                    opacity: 1,
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={onCodeButtonClick}
                            >
                            </motion.button>
                        </div>) : (
                            <div className="flex flex-row justify-between items-end gap-4">
                            <input 
                                type="text"
                                placeholder="Code"
                                onChange={handleCodeChange}
                                name="code"
                                value={code ? code : ""}
                                autoComplete="off"
                                className="bg-[#121220] border-b border-[#aaaaaa80] focus:outline-none focus:border-b-[#cccccc] text-xl w-full py-1 mt-6"
                            />
                            <motion.button 
                                className="bg-[url('/public/images/textIcon.png')] bg-cover w-[35px] h-[30px]"
                                initial={{ scale: 1, opacity: 0.5 }}
                                whileHover={{
                                    scale: 1.1,
                                    opacity: 1,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.9,
                                    opacity: 1,
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={onTextButtonClick}
                            >
                            </motion.button>
                        </div>
                        )}  
                        {!paste ? (<input 
                            type="text"
                            placeholder="Number of Credits"
                            onChange={handleChange}
                            name="credits"
                            value={formData.credits}
                            autoComplete="off"
                            className="bg-[#121220] border-b border-[#aaaaaa80] focus:outline-none focus:border-b-[#cccccc] text-xl w-full py-1 mt-4"
                        />) : (
                            <input 
                            type="text"
                            placeholder=""
                            name="credits"
                            autoComplete="off"
                            className="opacity-0 bg-[#121220] border-b border-[#aaaaaa80] focus:outline-none focus:border-b-[#cccccc] text-xl w-full py-1 mt-4 cursor-default hover:cursor-default"
                        />
                        )}
                        <div className="flex flex-row justify-between mt-4">
                            <motion.button
                                className="border-4 py-2 w-[45%] flex flex-row justify-around mt-4"
                                initial={{ scale: 1 }}
                                whileHover={{ 
                                    scale: 1.05,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.95, 
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={handleBack}
                            >
                                <img 
                                    src="/images/backArrow.png"
                                    alt=""
                                    className="w-[50px]"
                                />
                            </motion.button>
                            <motion.button
                                className="border-4 py-2 w-[45%] flex flex-row justify-around mt-4 items-center font-bold xs:text-xl text-lg bg-[#FFA600] text-black"
                                initial={{ scale: 1 }}
                                whileHover={{ 
                                    scale: 1.05,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.95, 
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={handleSubmit}
                            >
                                CREATE
                            </motion.button>
                        </div>
                    </div>

                </motion.div>

            </motion.div>
            {/* POPUP WARNING SHTS */}
            <AnimatePresence>
            {popup.visible && (
                <Popup text={popup.text} image={popup.image} />
            )}
            </AnimatePresence>
        </div>
    )
}