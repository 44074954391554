import { Route, Routes } from 'react-router-dom';
import './output.css';
import Home from "./pages/Home";
import Login from './pages/Login';
import Signup from './pages/Signup';
import Learnmore from './pages/Learnmore';
import Dashboard from './pages/Dashboard';
import Welcome from './pages/Welcome';
import Calendar from './pages/Calendar'
import CoursePage from './pages/CoursePage';
import GPACalculator from './pages/GPACalculator';
import Archive from './pages/Archive';
import FourOhFour from './pages/FourOhFour';
import Info from './pages/Info';
import Error from './pages/Error';

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/learnmore" element={<Learnmore />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/:index" element={<CoursePage />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/calculator" element={<GPACalculator />} />
            <Route path="/archive" element={<Archive />} />
            <Route path="/info" element={<Info />} />
            <Route path="/error" element={<Error />} />
            <Route path="/*" element={<FourOhFour />} />
        </Routes>
    );
}

export default App;
