import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

export default function RevealCard({ src, text }) {

    const revealCardRef = useRef(null);
    const revealCardSYP = useScroll({
        target: revealCardRef,
        offset: ["start end", "end start"]
    }).scrollYProgress;
    const textXPos = useTransform(revealCardSYP, [0, 0.1, 0.4, 1], [-500, -500, 0, 0]);
    const opacity = useTransform(revealCardSYP, [0, 0.1, 0.4, 1], [0, 0, 1, 1]);

    return (
        <div className="flex flex-col mdplus:flex-row w-4/5 mx-auto justify-between items-center mt-8">
            <motion.img 
                src={src}
                alt=""
                className="mdplus:w-[60%] lg:w-[45%] z-10 border-4 w-full"
                ref={revealCardRef}
            />
            <motion.p 
                className="font-montserrat text-white text-xl lg:text-2xl lg:w-[45%] mdplus:w-[35%] mdplus:font-light lg:font-semibold mdplus:text-left text-center mdplus:mt-0 mt-4 font-bold"
                style={{ opacity: opacity, x: textXPos }}
            >{text}</motion.p>
        </div>
    )
}