import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AnimatePresence, motion, useAnimate } from "framer-motion";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import NavbarLink from "./NavbarLink";

export default function Navbar({ opaque }) {
    const [top, animateTop] = useAnimate();
    const [middle, animateMiddle] = useAnimate();
    const [bottom, animateBottom] = useAnimate();
    const [openMenu, setOpenMenu] = useState(false);
    const [signedIn, setSignedIn] = useState(false);

    const navigate = useNavigate();

    async function handleMenuButtonAnimate() {
        if (!openMenu) {
            animateTop(top.current, { rotate: 45, y: 10 })
            animateMiddle(middle.current, { opacity: 0 })
            animateBottom(bottom.current, { rotate: -45, y: -10 })
        }
        else {
            animateTop(top.current, { rotate: 0, y: 0 })
            animateMiddle(middle.current, { opacity: 1 })
            animateBottom(bottom.current, { rotate: 0, y: 0 })
        }
        setOpenMenu(prevOpenMenu => !prevOpenMenu)
    }

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // FIREBASE STUFFS START HERE --------------------------------------------------------------------------------------------
    const auth = getAuth();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setSignedIn(true);
            }
            else {
                setSignedIn(false);
            }
        })
    }, []);

    function handleSignOut() {
        signOut(auth).then(() => {
            setSignedIn(false);
        }).catch((error) => {
            navigate("/error");
        });
    }

    return (
        <div className={ opaque ? "bg-[#121220] border-b border-[#ffffff20] w-full h-[75px] fixed top-0 z-50" : "bg-[#121220fa] border-b border-[#ffffff20] w-full h-[75px] fixed top-0 z-50" }>

            {/* CODE FOR THE ACTUAL MENU OPENED HERE */}
            <AnimatePresence>
                {openMenu && (viewportWidth < 768 || signedIn) && (
                <motion.div 
                    className="text-white absolute right-0 top-[75px] w-[250px] bg-[#000000ee] text-2xl font-montserrat p-6 z-10 h-screen"
                    initial={{ x: 300, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{
                        duration: 1,
                        ease: [0.01, 1, 0.5, 1]
                    }}
                    exit={{ x: 300, opacity: 0}}
                >
                    {!signedIn && <><NavbarLink to="/login" text="LOG IN" /><br /></>} 
                    {!signedIn && <><NavbarLink to="/signup" text="SIGN UP" /><br /></>}

                    {signedIn && <><NavbarLink to="/" text="HOME" /><br /></>}
                    {signedIn && <><NavbarLink to="/welcome" text="PROFILE" /><br /></>}
                    {signedIn && <><NavbarLink to="/dashboard" text="DASHBOARD" /><br /></>}
                    {signedIn && <><NavbarLink to="/calendar" text="CALENDAR" /><br /></>}
                    {signedIn && <><NavbarLink to="/calculator" text="GPA TARGET" /><br /></>}
                    {signedIn && <><NavbarLink to="/archive" text="THE ARCHIVE" /><br /></>}
                    {signedIn && <><div onClick={handleSignOut} ><NavbarLink text="SIGN OUT" /></div><br /></>}

                </motion.div>
                )}
            </AnimatePresence>





            <div className="w-4/5 mx-auto flex flex-row justify-between items-center h-full">

                <Link to="/">
                    <div className="flex flex-row items-center justify-between gap-2 sm:gap-4 hover:cursor-pointer">
                        <img 
                            className="h-[50px]"
                            src="/images/logo.png" 
                            alt="skule logo"
                        />
                        <p 
                            className="hidden xs:block text-xl sm:text-2xl font-montserrat font-semibold bg-gradient-to-r from-[#aaaaaa] via-[#dddddd] to-[#aaaaaa] text-transparent bg-clip-text"
                        > 
                            SKULE TRACKER
                        </p>
                    </div>
                </Link>

                {/* DIV OF THE BURGER MENU BUTTON CODE STARTS HERE */}

                {((viewportWidth < 768 && !signedIn) || signedIn) && <motion.div
                    initial={{ scale: 1.0 }}
                    whileHover={{
                        scale: 1.15,
                        transition: { duration: 0.25 }
                    }}
                    whileTap={{ 
                        scale: 0.9,
                        transition: { duration: 0.125 }
                    }}
                    className="w-[30px] h-[30px] flex flex-col justify-between py-1"
                    onClick={() => handleMenuButtonAnimate()}
                >

                    <motion.div
                        className="border border-[#ffffff70] w-full"
                        ref={top}
                    >
                    </motion.div>

                    <motion.div
                        className="border border-[#ffffff70] w-full"
                        ref={middle}
                    >
                    </motion.div>

                    <motion.div
                        className="border border-[#ffffff70] w-full"
                        ref={bottom}
                    >
                    </motion.div>

                </motion.div>}

                {/* DIV OF THE BURGER MENU BUTTON CODE ENDS HERE */}

                {/* LARGER SCREEN NAVBAR (NO BURGER MENU) STARTS HERE */}

                {(!signedIn || (viewportWidth < 768 && signedIn)) && <div className="w-fit hidden md:flex md:flex-row items-center justify-between h-[40%]">

                    {!signedIn && <NavbarLink to="/login" text="LOG IN" />}
                    {!signedIn && <div className="border h-full mx-4 border-[#ffffff40]"></div>}
                    {!signedIn && <NavbarLink to="/signup" text="SIGN UP" />}           

                </div>}

                {/* LARGER SCREEN NAVBAR (NO BURGER MENU) CODE ENDS HERE */}

            </div>

        </div>
    )
}