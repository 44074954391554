import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

export default function CustomCalendarItem({ keyData, onPointerDownFn, onPointerUpFn, 
                                             name, courseName, complete, current, clickTask, taskIndex, setEditIndex }) {

    const tailwindClassName = `text-xs 2xl:text-sm font-semibold xl:h-[90px] 2xl:h-[100px] bg-[#1c2a32] active:bg-[#2b3b47] rounded-lg mt-2 p-2 flex flex-col gap-2 ${current ? "" : "opacity-60 hover:opacity-100"}`

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const mobileClassName = `rounded-full w-[10px] h-[10px] md:m-2 m-1 ${!complete ? "bg-[#121220] border" : "bg-[#5271ff]"}`

    if (viewportWidth >= 1024) {
        return (
            <motion.div
                key={keyData}
                className={tailwindClassName}
                drag
                dragSnapToOrigin="true"
                dragElastic={0}
                dragTransition={{ power: 0 }}
                onPointerDown={onPointerDownFn}
                onPointerUp={onPointerUpFn}
                onClick={(e) => {
                    e.stopPropagation();
                    clickTask(e, taskIndex);
                    setEditIndex(taskIndex);
                }}
                name="item"
            >
                <p className="font-dosis uppercase tracking-widest truncate">{name}</p>
                <p className="font-normal truncate">{courseName}</p>
                <div className="flex flex-row lg:gap-1 xl:gap-2 relative top-[3px]">
                    <img 
                        src={complete ? "/images/checkedBox.svg" : "/images/uncheckedBox2.svg"}
                        alt=""
                        className="lg:w-[15px] xl:w-[20px] hover:cursor-pointer"
                        name="completeButton"
                        draggable="false"
                    />
                    <p 
                        className="font-normal hover:cursor-pointer relative 2xl:top-0 xl:top-[2px]" 
                        name="completeButton"
                    >
                        Complete
                    </p>
                </div>
            </motion.div>
        )
    }
    else {
        return (
            <div 
                className={mobileClassName}
            >
            </div>
        )
    }
} 