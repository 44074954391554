import { motion } from "framer-motion";
import React from "react";

export default function Popup({ image, text }) {
    const popUpMask = {
        initial: {
            clipPath: 'inset(0 0 0 0)',
        },
        animate: {
            clipPath: 'inset(0 0 0 100%)',
            transition: {
                ease: "linear",
                duration: 3,
            }
        },
    }

    let warning = false;
    if (image === "/images/warning.png") {
        warning = true;
    }

    return (
        <motion.div 
            className="fixed top-[120px] text-white bg-[#121220] border-2 xs:border-4 m-4 right-0 font-montserrat"
            initial={{ x:500 }}
            animate={{ x:0 }}
            transition={{
                duration: 0.5,
                ease: [0, 0.79, 0.3, 0.99],
            }}
            exit={{ x:200, opacity: 0,
                transition: {
                    duration: 0.5
                }
                }}
        >
            <div className="flex flex-row justify-around items-center gap-4 md:gap-6 p-4 md:p-6 lg:p-8">
                <img 
                    src={image}
                    alt=""
                    className="w-[25px] sm:w-[30px] md:w-[35px] lg:w-[40px]"
                />
                <p className="text-xs sm:text-sm md:text-base">{text}</p>
            </div>
            <motion.div 
                className={warning ? "w-full h-[6px] md:h-[8px] bg-[#d34728]" : "w-full h-[8px] bg-[#59FEBB]"}
                variants={popUpMask}
                initial="initial"
                animate="animate"
                exit={{opacity: 0}}
            ></motion.div>
        </motion.div>
    )
}