import React from "react";
import DashboardNavbar from "../components/DashboardNavbar";
import InfoCard from "../components/InfoCard";

export default function Info() {
    return (
        <div className="font-montserrat relative">
            <DashboardNavbar />
            <div className="relative top-[75px] text-white bg-[#121220] bg-[url('/public/images/pattern.png')] bg-repeat bg-cover min-h-[calc(100vh-75px)]">
                <div className="pt-32 w-4/5 mx-auto pb-32">
                    <p className="text-lg xs:text-xl lg:text-2xl text-center w-full border-b border-b-[#ffffff50] pb-1">This code gives you the ability to share your course data with a friend. Here are the steps to copy a course!</p>
                    <InfoCard 
                        src="/images/info1.png"
                        number="1"
                        text1="Head to the"
                        linkText="Dashboard"
                        linkURL="/dashboard"
                        text2="and click on"
                        boldEndText="Add Course"
                    />
                    <InfoCard
                        src="/images/info2.png"
                        number="2"
                        text1="Click on the"
                        boldEndText="course code icon"
                    />
                    <InfoCard
                        src="/images/info3.png"
                        number="3"
                        text1="Paste in your uniquely generated"
                        boldEndText="course code"
                    />
                    <InfoCard
                        src="/images/info4.png"
                        number="4"
                        text1="Click on create! You will be directed to a copy of the course"
                        boldEndText="in your account"
                    />
                </div>
            </div>
        </div>
    )
}