import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function FourOhFour() {
    return (
        <div className="font-montserrat relative">
            <Navbar opaque={true} />
            <div className="relative top-[75px] text-white bg-[#121220] min-h-[calc(100vh-75px)] bg-[url('/public/images/pattern.png')] bg-repeat bg-cover">
                <div className="w-4/5 mx-auto">
                    <p className="text-7xl font-bold text-center pt-32">404</p>
                    <p className="text-white text-3xl text-center pt-8 font-light">The page you are looking for <span className="font-bold">doesn't exist {":("}</span></p>

                    <div className="w-fit mx-auto mt-32">
                        <Link to="/">
                            <motion.button 
                                className="text-black border-4 p-2 mt-6 text-base text-xl font-semibold w-[230px] flex flex-row items-center justify-around bg-[#FFA600]"
                                initial={{ scale: 1.0 }}
                                whileHover={{
                                    scale: 1.1,
                                    transition: { duration: 0.25 }
                                }}
                                whileTap={{ 
                                    scale: 0.95,
                                    transition: { duration: 0.125 }
                                }}
                            >
                                Back To Home
                                <img 
                                    src="/images/learnMoreArrow.png"
                                    alt="arrows"
                                    className="w-[15%]"
                                    /> 
                            </motion.button>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    )
}