import React from "react";
import { Link } from "react-router-dom";

export default function InfoCard({ src, number, text1,
                                   linkText = "", linkURL = "", 
                                   text2 = "", boldEndText = ""}) {
    const className = `flex flex-col gap-8 md:flex-row justify-between w-[100%] items-center mt-8 pb-16 md:pb-0 ${number === "4" ? "" : "border-b"} md:border-none`
    return (
        <div className={className}>
            <p
                className="mt-4 text-base xs:text-xl mdplus:text-2xl lg:text-3xl w-full md:text-left text-center md:w-[40%] font-light"
            >
                <span className="text-2xl xs:text-3xl sm:text-4xl lg:text-5xl font-semibold">{number}. </span>
                {`${text1} `}
                <Link to={linkURL} className="text-blue-500 hover:underline">{linkText}</Link> 
                {` ${text2} `}
                <span className="font-semibold">{boldEndText}</span>
            </p>
            <img 
                className="w-full xs:w-4/5 md:w-[55%] border-4"
                src={src}
            />
        </div>
    )
}