import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";

export default function FeatureCard({ image, title, desc, loggedIn = false, index = 0 }) {
    const ref = useRef();
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start end", "end start"]
    });

    const opacity = useTransform(scrollYProgress, [0, 0.4, 0.6, 1], [0, 1, 1, 0]);
    const scale = useTransform(scrollYProgress, [0, 0.4, 0.6, 1], [0.5, 1, 1, 0.5]);

    let buttonText = "Learn More";
    let link = "/learnmore";
    if (loggedIn && index === 0) {
        buttonText = "To Dashboard";
        link = "/dashboard";
    }
    else if (loggedIn && index === 1) {
        buttonText = "To GPA Target";
        link = "/calculator";
    }
    else if (loggedIn && index === 2) {
        buttonText = "To Calendar";
        link = "/calendar";
    }

    return (
        <motion.div 
            className="flex md:flex-row flex-col md:border-4 md:rounded-3xl md:bg-[#121220aa] justify-between w-full my-4 md:my-12 "
            ref={ref}
            style={{
                scale: scale,
                opacity: opacity
            }}
        >
            <motion.div
                className="xl:w-1/4 lg:w-[30%] w-[40%] flex items-center mx-auto"
                initial={{ scale: 0.8 }}
                whileHover={{ 
                    scale: 0.9,
                    transition: { duration: 0.25 }
                }}
            >
                <img 
                    src={image}
                    alt="Feature card"
                    className="w-full"
                />
            </motion.div>
            <div className="w-full md:w-3/4 md:py-12 py-6 md:pr-12 lg:pl-4 pl-0 md:flex md:flex-col md:justify-between md:border-0 border-t-2 md:text-left text-center">
                <div className="mx-auto md:m-0">
                    <div className="flex flex-row items-center justify-center md:justify-start md:w-[380px] mb-2 lg:mb-4 mx-auto md:mx-0">
                        <img
                            src="/images/homeCardCheckmark.png"
                            alt="checkmark"
                            className="w-[8%] xl:w-[10%] "
                        />
                        <p className="bg-gradient-to-r from-[#aaaaaa] via-[#dddddd] to-[#aaaaaa] text-transparent bg-clip-text font-semibold text-2xl xl:text-4xl w-fit px-2">
                            {title}
                        </p>
                    </div>
                    <p className="text-white text-sm xs:text-base md:text-sm lg:text-base xl:text-lg 2xl:text-xl">
                        {desc}
                    </p>
                </div>


                <div className="w-fit mx-auto md:m-0">
                    <Link to={link}>
                        <motion.button 
                            className="text-black border-4 p-1 xl:p-2 mt-6 text-base lg:text-lg xl:text-xl font-semibold w-[160px] lg:w-[190px] xl:w-[230px] flex flex-row items-center justify-around bg-[#FFA600]"
                            initial={{ scale: 1.0 }}
                            whileHover={{
                                scale: 1.1,
                                transition: { duration: 0.25 }
                            }}
                            whileTap={{ 
                                scale: 0.95,
                                transition: { duration: 0.125 }
                            }}
                        >
                            {buttonText}
                            <img 
                                src="/images/learnMoreArrow.png"
                                alt="arrows"
                                className="w-[10%] lg:w-[12%] xl:w-[15%]"
                                /> 
                        </motion.button>
                    </Link>
                </div>
                
            </div>


        </motion.div>
    )
}