import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { motion } from "framer-motion";
import FeatureCard from "../components/FeatureCard";
import { Link } from "react-router-dom";
import { data } from "../data/homeCardData";
import Title from "../components/Title";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default function Home() {    
    const lastPage = JSON.parse(sessionStorage.getItem("lastPage"));
    let contentDelay = 0;
    
    // add page names to this array if you want delay to happen
    // after coming from these pages. else ignore.
    const delayPages = ['home'];
    if (delayPages.includes(lastPage) || !lastPage) {
        contentDelay = 2.2;
    }
    sessionStorage.setItem('lastPage', JSON.stringify('home'));

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const auth = getAuth();
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);
            }
        });
    }, []);

    // FRAMER SHTS START HERE
    // const ref = useRef();
    // const { scrollYProgress } = useScroll({
    //     target: ref,
    //     offset: ["start end", "end start"]
    // });

    // const positionY = useTransform(scrollYProgress, [0, 1], [-300, 300]);

    const navbar = {
        initial: {
            y: -80
        },
        animate: {
            y: 0,
            transition: { 
                ease: [0.01, 0.9, 0.86, 0.99],
                delay: contentDelay,
                duration: contentDelay ? 1 : 0
            }
        }
    }

    const fadeIn = {
        initial: { 
            opacity: 0
        },
        animate: {
            opacity: 1,
            transition: {
                delay: contentDelay,
                duration: 1
            }
        }
    }

    const homeCards = data.map((info, index) => {
        if (!isLoggedIn) {
            return (
                <FeatureCard 
                    key={index}
                    image={info.image} 
                    title={info.title} 
                    desc={info.desc}
                />
            )
        }
        return (
            <FeatureCard 
                key={index}
                index={index}
                loggedIn={true}
                image={info.image} 
                title={info.title} 
                desc={info.desc}
            />
        )
    });

    return (
        <motion.div 
            className="font-montserrat bg-[#121220] bg-[url('/public/images/pattern.png')] bg-repeat bg-cover"
            // style={{
            //     backgroundPositionY: positionY
            // }}
        >
            <motion.div
                variants={navbar}
                initial="initial"
                animate="animate"
            >
                <Navbar />
            </motion.div>

            <motion.div 
                className="w-4/5 mx-auto h-full relative"
            >
                <Title contentDelay={contentDelay}/>
                
                <motion.div 
                    // ref={ref}
                    className="mt-32 flex flex-col justify-between"
                    variants={fadeIn}
                    initial="initial"
                    animate="animate"
                >
                    {homeCards}
                    
                </motion.div>
                

                <div className="w-fit mx-auto py-32 text-center">
                    <p
                        className="font-montserrat text-3xl sm:text-5xl font-bold bg-gradient-to-r from-[#aaaaaa] via-[#dddddd] to-[#aaaaaa] text-transparent bg-clip-text p-2"
                    >
                        Ready to excel academically?
                    </p>

                    <Link to="/login">
                        <motion.button 
                            className="text-white sm:text-3xl text-xl font-montserrat font-semibold border-4 xs:w-[40%] w-2/3 sm:p-2 p-1 mt-6 bg-[#121220]"
                            initial={{ scale: 1.0 }}
                            whileHover={{
                                scale: 1.1,
                                transition: { duration: 0.25 }
                            }}
                            whileTap={{ 
                                scale: 0.9,
                                transition: { duration: 0.125 }
                            }}
                        >
                            LOG IN
                        </motion.button>
                    </Link><br />
                    <Link to="/signup">
                        <motion.button 
                            className="text-black sm:text-3xl text-xl font-montserrat font-semibold border-4 xs:w-[40%] w-2/3 sm:p-2 p-1 mt-6 bg-[#FFA600]"
                            initial={{ scale: 1.0 }}
                            whileHover={{
                                scale: 1.1,
                                transition: { duration: 0.25 }
                            }}
                            whileTap={{ 
                                scale: 0.9,
                                transition: { duration: 0.125 }
                            }}
                        >
                            SIGN UP
                        </motion.button>
                    </Link>

                    
                </div>
            </motion.div>
        </motion.div>
    )
}