import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import DashboardNavbar from "../components/DashboardNavbar";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import CourseCard from "../components/CourseCard";
import { AnimatePresence } from "framer-motion";
import Popup from "../components/Popup";

export default function Archive() {
    const [userID, setUserID] = useState(null);
    const [courseData, setCourseData] = useState([]);
    const navigate = useNavigate();

    const auth = getAuth();
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            // if the user is signed in 
            if (user) {
                const uid = user.uid;
                setUserID(uid);
                const docRef = doc(db, "users", user.uid);
                getDoc(docRef)
                    .then((docSnap) => {
                        if (docSnap.exists() && docSnap.data().welcome) {
                            navigate("/welcome");
                        }
                        if (docSnap.exists() && !docSnap.data().welcome) {
                            setCourseData(docSnap.data().courses);
                        }
                    }).catch((error) => {
                        navigate("/error");
                    }
                );
            }
                
            // if the user is NOT signed in
            else {
                navigate("/");
            }
        });
    }, []);

    sessionStorage.setItem('lastPage', JSON.stringify('archive'))

    let courses = [];
    let emptyArchive = true;
    if (courseData.length !== 0) {
        courses = courseData.map((data, index) => {
            if (data.archived) {
                emptyArchive = false;
                return (
                    <CourseCard 
                        index={index}
                        key={index}
                        name={data.name}
                        target={data.target}
                        avg={(Number(data.weightAchieved)/Number(data.weightPossible)*100).toFixed(2)}
                        weightAchieved={data.weightAchieved}
                        weightPossible={data.weightPossible}
                        handleArchiveClick={handleArchiveClick}
                        fromArchive={true}
                    />
                )
            }
        });
    }

    const [popup, setPopup] = useState({
        visible: false,
        text: "",
        image: ""
    });

    /**
     * 
     * @param {string} text 
     * @param {string} image 
     */
    function handlePopup(text, image) {
        if (!popup.visible) {
            setPopup(() => {
                return {
                    visible: true,
                    text: text,
                    image: image
                }
            });
            setTimeout(() => setPopup({
                visible: false,
                text: "",
                image: ""
            }), 3000);
        }
        else {
            setPopup(() => {
                return {
                    visible: true,
                    text: text,
                    image: image
                }
            });
        }
    }
    
    function handleArchiveClick(index) {
        setCourseData(prevData => {
            prevData[index].archived = false;

            handlePopup(`Successfully removed ${prevData[index].name} from the archive`, "/images/checkmark.png");

            updateDB(prevData);

            return prevData;
        });
    }

    async function updateDB(data) {
        const docRef = doc(db, "users", userID);
        await updateDoc(docRef, {
            courses: data
        });
    }

    return (
        <div className="font-montserrat relative">
            <DashboardNavbar />
            <div className="relative top-[75px] text-white bg-[#121220] min-h-[calc(100vh-75px)]">
                <div className="w-4/5 mx-auto pt-16 grid grid-cols-1 mdplus:grid-cols-2 2xl:grid-cols-3 gap-8 xl:gap-16 2xl:gap-8 pb-64">
                    {courses}
                </div>
                {emptyArchive && <div className="text-white font-montserrat text-2xl text-center font-bold">There are no archived courses at this time.</div>}
            </div>


            {/* POPUP WARNING SHTS */}
            <AnimatePresence>
            {popup.visible && (
                <Popup text={popup.text} image={popup.image} />
            )}
            </AnimatePresence>
        </div>
    )
}