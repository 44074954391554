import React, { useState, useEffect } from "react";
import DashboardNavbar from "../components/DashboardNavbar";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { motion, useAnimate, AnimatePresence } from "framer-motion";
import { scaleData } from "../data/gpascales";
import Popup from "../components/Popup";
import { exists } from "../pages/CoursePage";

export default function GPACalculator() {

    const [userID, setUserID] = useState(null);
    const navigate = useNavigate();

    // format of customScale is a nested list, similar to those predefined below (fourPointThreeScaleData, etc)
    const [, setCustomScale] = useState(false);

    const [courseData, setCourseData] = useState(null);

    const [plannedCredits, setPlannedCredits] = useState(null);
    const [editHeader, setEditHeader] = useState(false);
    const [gpaTarget, setGpaTarget] = useState(null);

    // 0 -> 4.3, 1 -> 4.0 v1, 2 -> 4.0 v2
    const [selection, setSelection] = useState(0);

    const [scope, animate] = useAnimate();

    const auth = getAuth();
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                setUserID(uid);
                
                const docRef = doc(db, "users", uid);
                getDoc(docRef)
                    .then((docSnap) => {
                        if (docSnap.exists()) {
                            // user's data was found
                            setCustomScale(docSnap.data().customGPAScale);
                            setCourseData(docSnap.data().courses);
                            setPlannedCredits(docSnap.data().plannedCredits);
                            setGpaTarget(docSnap.data().gpatarget);
                        }
                        else {
                            // user's data was not found
                            navigate("/error");
                        }
                    }).catch((error) => {
                        // something went wrong
                        navigate("/error");
                    }
                );
            }
            else {
                navigate("/");
            }
        });
    }, []);

    const [popup, setPopup] = useState({
        visible: false,
        text: "",
        image: ""
    });

    /**
     * 
     * @param {string} text 
     * @param {string} image 
     */
    function handlePopup(text, image) {
        if (!popup.visible) {
            setPopup(() => {
                return {
                    visible: true,
                    text: text,
                    image: image
                }
            });
            setTimeout(() => setPopup({
                visible: false,
                text: "",
                image: ""
            }), 3000);
        }
        else {
            setPopup(() => {
                return {
                    visible: true,
                    text: text,
                    image: image
                }
            });
        }
    }

    function makeScale(scale, index) {
        const preScale = scale.data.map((data, index) => {
            return (
                <div className="border-b border-b-[#ffffff20] text-[#ffffff50] mt-2 flex flex-row justify-between" key={index}>
                    <p>
                        {data[0].toFixed(1)}
                    </p>
                    <div className="w-[80px] flex flex-row justify-end">
                        <p>{data[1]} - {data[2] === 100 ? 100 : (data[2] - 0.1)}</p>
                    </div>
                </div>
            )
        });

        return (
            <motion.div 
                className="sm:w-[75%] w-[90%] mx-auto font-dosis tracking-widest"
                onClick={() => handleChangeSelection(index)}
            >
                <div className="flex flex-row border-b border-b-[#ffffff60] justify-between items-end">
                    <p className="text-2xl pb-1">{scale.name}</p>
                    <p className="hidden xs:block text-lg text-[#ffffff50] pb-0.5">PERCENTAGE</p>
                </div>
                {preScale}
            </motion.div>
        )
    }

    const fourPointThreeScale = makeScale(scaleData[0], 0);

    const fourPointOhScaleI = makeScale(scaleData[1], 1);

    const fourPointOhScaleII = makeScale(scaleData[2], 2);

    const fourPointOhScaleIII = makeScale(scaleData[3], 3);

    function handleChangeSelection(index) {
        if (index === selection) {
            setSelection(0);
        }
        else {
            setSelection(index);
        }
    }

    let GPA = 0;
    let totalCredits = 0;
    let totalGradePoints = 0;
    const scale = scaleData[selection].data;
    for (let i = 0; i < (courseData ? courseData.length : 0); i++) {
        if (courseData[i].weightPossible === 100) {
            totalCredits += Number(courseData[i].credits);
        }

        const courseMark = (courseData[i].weightAchieved / courseData[i].weightPossible) * 100;
        for (let j = 0; j < scale.length; j++) {
            if (courseMark >= scale[j][1] && courseMark < scale[j][2] && courseData[i].weightPossible === 100) {
                totalGradePoints += courseData[i].credits * scale[j][0];
            }
        }
    }

    const reqRemainingGradePoints = (plannedCredits * gpaTarget) - (totalGradePoints);
    let reqGPA = reqRemainingGradePoints / (plannedCredits - totalCredits);
    if (reqGPA <= 0) {
        reqGPA = 0;
    }
    let minCourseReqGPA = scale[scaleData[selection].data.length - 1][0];
    if (reqGPA > scale[0][0]) {
        minCourseReqGPA = 'impossible';
    }
    else {
        for (let i = scale.length - 1; i >= 0; i--) {
            const lowestgpa = scale[i][0];
            if (lowestgpa >= reqGPA) {
                minCourseReqGPA = lowestgpa;
                break;
            }
        }
    }

    if (totalCredits !== 0 && totalGradePoints !== 0) {
        GPA = totalGradePoints / totalCredits;
    }

    const scaleArr = [
        fourPointThreeScale,
        fourPointOhScaleI,
        fourPointOhScaleII,
        fourPointOhScaleIII
    ];

    async function handleClickLeft() {
        // const scaleContainer = document.getElementById('scaleContainer');
        // const width = scaleContainer.offsetWidth * 0.9;

        await animate(scope.current, { x: 100, opacity: 0 }, { duration: 0.1 });
        setSelection(prev => {
            let newSelection = prev - 1;
            if (prev === 0) {
                newSelection = Object.keys(scaleData).length - 1;
            }

            if (gpaTarget > scaleData[newSelection].data[0][0]) {
                setGpaTarget(scaleData[newSelection].data[0][0]);
            }

            return newSelection;
        });
        await animate(scope.current, { x: -100 }, { duration: 0.001 });
        await animate(scope.current, { x: 0, opacity: 1 }, { duration: 0.1 });
    }

    async function handleClickRight() {
        await animate(scope.current, { x: -100, opacity: 0 }, { duration: 0.1 });
        setSelection(prev => {
            let newSelection = prev + 1;
            if (prev === Object.keys(scaleData).length - 1) {
                newSelection = 0;
            }

            if (gpaTarget > scaleData[newSelection].data[0][0]) {
                setGpaTarget(scaleData[newSelection].data[0][0]);
            }

            return newSelection;
        });
        await animate(scope.current, { x: 100 }, { duration: 0.001 });
        await animate(scope.current, { x: 0, opacity: 1 }, { duration: 0.1 });
    }

    function handleClickEdit() {
        setEditHeader(prev => !prev);
    }

    function handleClickSave() {
        if (plannedCredits !== 0 && plannedCredits >= totalCredits) {
            // on successful save
            setEditHeader(prev => !prev);

            updateDBCredits(Number(plannedCredits));
        }
        else if (plannedCredits == 0) {
            handlePopup("Cannot plan 0 credits", "/images/warning.png");
        }
        else if (plannedCredits < totalCredits) {
            handlePopup("Cannot plan less than taken credits", "/images/warning.png");
        }

        updateDBTarget(Number(gpaTarget));
    }

    async function updateDBCredits(data) {
        const docRef = doc(db, "users", userID);
        await updateDoc(docRef, {
            plannedCredits: data
        });
    }

    async function updateDBTarget(data) {
        const docRef = doc(db, "users", userID);
        await updateDoc(docRef, {
            gpatarget: data
        });
    }

    function handleCreditsChange(e) {
        const { value } = e.target;
        setPlannedCredits(prev => {
            if (isNaN(Number(value))) {
                return prev;
            }
            else {
                return value;
            }
        });
    }

    function handleTargetChange(e) {
        const { value } = e.target;
        setGpaTarget(prev => {
            if (isNaN(Number(value))) {
                return prev;
            }
            else if (Number(value) > scaleData[selection].data[0][0]) {
                return prev;
            }
            else {
                return value;
            }
        });
    }
    
    return (
        <div className="relative top-[75px] text-white bg-[#121220] min-h-[calc(100vh-75px)]">
            <DashboardNavbar />
            <div className="w-4/5 mx-auto">
                <div className="w-full border-b border-b-2 mb-12 pb-1 pt-16 flex mdplus:flex-row flex-col mdplus:justify-between">
                    <h1 className="text-3xl lg:text-4xl xl:text-5xl font-montserrat font-semibold">Select GPA Scale</h1>
                    {!editHeader && <div className="flex flex-row xs:items-end items-center justify-between gap-4">
                        <div className="flex flex-col xs:flex-row gap-2 sm:gap-4 justify-between xs:w-[95%] w-3/4 mt-2">
                            <p className="font-dosis text-sm sm:text-base lg:text-lg xl:text-xl tracking-widest align-items">PLANNED CREDITS: {exists(plannedCredits) ? plannedCredits : "N/A"}</p>
                            <div className="xs:border-r xs:border-r-[#ffffff50] xs:h-[20px] xs:relative xs:bottom-[-3px] border-b border-b-[#ffffff50]"></div>
                            <p className="font-dosis text-sm sm:text-base lg:text-lg xl:text-xl tracking-widest align-items">TARGET GPA: {exists(gpaTarget) ? gpaTarget : "N/A"}</p>
                        </div>
                        <motion.img 
                            src="/images/edit.png"
                            alt=""
                            className="w-[40px] xs:w-[30px] xl:w-[35px] relative xs:bottom-[2px] bottom-[-2px]"
                            initial={{ scale: 1 }}
                            whileHover={{
                                scale: 1.1,
                                transition: {
                                    duration: 0.25
                                }
                            }}
                            whileTap={{
                                scale: 0.9,
                                transition: {
                                    duration: 0.125
                                }
                            }}
                            onClick={handleClickEdit}
                        />
                    </div>}
                    {editHeader && <div className="flex flex-row justify-between gap-4 xs:items-end items-center">
                        <div className="flex flex-col xs:flex-row gap-2 sm:gap-4 justify-between xs:w-[95%] w-3/4 mt-2">
                            <input 
                                type="text"
                                className="bg-[#121220] border-b border-b-[#ffffff50] h-[20px] mb-0.5 font-dosis text-sm sm:text-base lg:text-lg xl:text-xl tracking-widest focus:outline-none"
                                autoComplete="off"
                                placeholder="PLANNED CREDITS"
                                value={plannedCredits}
                                name="credits"
                                onChange={handleCreditsChange}
                            />
                            <div className="xs:border-r xs:border-r-[#ffffff50] xs:h-[20px] xs:relative xs:bottom-[-3px]"></div>
                            <input 
                                type="text"
                                className="bg-[#121220] border-b border-b-[#ffffff50] h-[20px] mb-0.5 font-dosis text-sm sm:text-base lg:text-lg xl:text-xl tracking-widest focus:outline-none"
                                autoComplete="off"
                                placeholder="GPA TARGET"
                                value={gpaTarget}
                                name="credits"
                                onChange={handleTargetChange}
                            />
                        </div>
                        <motion.img 
                            src="/images/checkmark2.png"
                            alt=""
                            className="w-[25px] xl:w-[28px] relative bottom-[2px] right-[2px]"
                            initial={{ scale: 1 }}
                            whileHover={{
                                scale: 1.1,
                                transition: {
                                    duration: 0.25
                                }
                            }}
                            whileTap={{
                                scale: 0.9,
                                transition: {
                                    duration: 0.125
                                }
                            }}
                            onClick={handleClickSave}
                        />
                    </div>}
                </div>

                <div className="xl:w-1/2 md:w-[80%] w-full sm:w-[92%] mdplus:w-3/4 mx-auto flex flex-row justify-between items-center">
                    <motion.button
                        className="sm:p-4 rounded-full"
                        initial={{
                            scale: 1
                        }}
                        whileHover={{
                            scale: 1.15,
                            transition: {
                                duration: 0.25
                            }
                        }}
                        whileTap={{
                            scale: 0.85,
                            transition: {
                                duration: 0.125
                            }
                        }}
                        onClick={handleClickLeft}
                    >
                        <img 
                            src="/images/lastMonthButton.png"
                            alt=""
                            className="w-[30px] h-[30px]"
                        />
                    </motion.button>
                    <div className="w-full overflow-hidden h-[480px]">
                        <div className="w-full" ref={scope} id="scaleContainer">
                            {scaleArr[selection]}
                        </div>
                    </div>
                    <motion.button
                        className="sm:p-4 rounded-full"
                        initial={{
                            scale: 1
                        }}
                        whileHover={{
                            scale: 1.15,
                            transition: {
                                duration: 0.25
                            }
                        }}
                        whileTap={{
                            scale: 0.85,
                            transition: {
                                duration: 0.125
                            }
                        }}
                        onClick={handleClickRight}
                    >
                        <img 
                            src="/images/nextMonthButton.png"
                            alt=""
                            className="w-[30px] h-[30px]"
                        />
                    </motion.button>


                </div>

                <div className="mt-12 pb-32">
                    <p className="text-2xl xs:text-3xl sm:text-4xl lg:text-5xl font-montserrat xl:w-1/2 mdplus:w-2/3 sm:w-4/5 xs:w-[90%] w-full border-b pb-2 text-center mx-auto pb-1">Your GPA is <span className="font-bold">{GPA.toFixed(3)}</span></p>
                    <p className="text-sm xs:text-base sm:text-lg lg:text-xl font-light font-montserrat text-center mx-auto xl:w-[45%] mdplus:w-[60%] sm:w-3/4 xs:w-[85%] w-[95%] mt-2">You have completed a total of <span className="font-bold">{totalCredits}</span> credits. You plan on taking <span className="font-bold">{plannedCredits - totalCredits}</span> more.</p>
                    {reqRemainingGradePoints >= 0 && <p className="text-sm xs:text-base sm:text-lg lg:text-xl font-light font-montserrat text-center mx-auto xl:w-[45%] mdplus:w-[60%] sm:w-3/4 xs:w-[85%] w-[95%] mt-2">You require an average GPA of <span className="font-bold">{reqGPA.toFixed(3)}</span> on all remaining credits to achieve your target.</p>}
                    {reqRemainingGradePoints >= 0 && minCourseReqGPA === 'impossible' ? (<p className="text-sm xs:text-base sm:text-lg lg:text-xl font-light font-montserrat text-center mx-auto xl:w-[45%] mdplus:w-[60%] sm:w-3/4 xs:w-[85%] w-[95%] mt-2">Unfortunately, it is <span className="font-bold">impossible</span> to achieve your goal. Maybe lower your target?</p>) :
                    (<p className="text-sm xs:text-base sm:text-lg lg:text-xl font-light font-montserrat text-center mx-auto xl:w-[45%] mdplus:w-[60%] sm:w-3/4 xs:w-[85%] w-[95%] mt-2">A GPA of <span className="font-bold">{minCourseReqGPA.toFixed(3)}</span> in every course will guarantee your goal.</p>)
                    }
                    {reqRemainingGradePoints < 0 && <p className="text-sm xs:text-base sm:text-lg lg:text-xl font-light font-montserrat text-center mx-auto xl:w-[45%] mdplus:w-[60%] sm:w-3/4 xs:w-[85%] w-[95%] mt-2">Looks like you've finished all of your planned credits!</p>}
                </div>

            </div>

            {/* POPUP WARNING SHTS */}
            <AnimatePresence>
            {popup.visible && (
                <Popup text={popup.text} image={popup.image} />
            )}
            </AnimatePresence>
        </div>
    )
}