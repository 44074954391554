import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { motion } from "framer-motion";
import "../scrollbar.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, DocumentSnapshot, getDoc, Timestamp, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

export default function CreateTaskMenu({ day, setShowAddCustomTask, createTask, changeTask, taskInfo }) {


    const [userID, setUserID] = useState(undefined);
    const [taskData, setTaskData] = useState([]);
    const navigate = useNavigate();

    const auth = getAuth();
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                setUserID(uid);
                
                const docRef = doc(db, "users", uid);
                getDoc(docRef).then((docSnap) => {
                    if (docSnap.exists() && docSnap.data().tasks) {
                        setTaskData(docSnap.data().tasks);
                    }
                    else {
                        // tasks field in the user data has not been created yet, so we will create it here
                        addTasksFieldToUserData(docRef);
                    }
                }).catch((error) => {
                    navigate("/error");
                });
            }
            else {
                navigate("/");
            }
        });
    }, []);

    async function addTasksFieldToUserData(theDocRef) {
        await updateDoc(theDocRef, {
            tasks: []
        });
    }

    return (
        <div 
            className="h-screen w-screen z-50 bg-black/60 fixed"
            onClick={() => {
                setShowAddCustomTask(false)
            }}
        >
            <div 
                className="w-4/5 sm:w-[66%] lg:w-1/2 bg-[#121c22] rounded-2xl relative left-[10%] sm:left-[17%] lg:left-[25%] top-[100px] p-4 xs:p-6 sm:p-12 overflow-x-hidden"
                onClick={(e) => {
                    e.stopPropagation();
                }}
                style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }} 
            >
                <div>
                    <p className="font-montserrat text-lg xs:text-2xl sm:text-4xl text-white font-light w-full">Add a task for</p>
                    <p className="font-montserrat text-white font-semibold text-base xs:text-xl sm:text-2xl lg:text-3xl xl:text-4xl w-full pb-2 border-b border-b-white/50">
                        {format(day, "eeee, LLLL do, yyyy")}
                    </p>
                </div>

                <div className="flex flex-col justify-between flex-grow">
                    <div className="flex flex-col gap-4 pt-8">
                        <div className="flex flex-row gap-1 xs:gap-4 mt-2 items-baseline">
                            <p className="font-montserrat font-normal xs:font-semibold text-xs xs:text-sm sm:text-base text-white/50">Name:</p>
                            <input 
                                type="text"
                                placeholder="Untitled"
                                name="name"
                                onChange={(e) => changeTask(e)}
                                value={taskInfo.name}
                                autoComplete="off"
                                className="bg-transparent font-montserrat text-lg xs:text-xl sm:text-2xl md:text-3xl font-semibold text-white select-none focus:outline-none placeholder-white placeholder-opacity-10 w-full"
                            />
                        </div>
                        <div className="flex flex-row gap-1 xs:gap-4 mt-2 items-baseline">
                            <p className="font-montserrat font-normal xs:font-semibold text-xs xs:text-sm sm:text-base text-white/50 w-fit">Class:</p>
                            <input 
                                type="text"
                                placeholder="Untitled"
                                name="class"
                                onChange={(e) => changeTask(e)}
                                value={taskInfo.class}
                                autoComplete="off"
                                className="bg-transparent font-montserrat text-lg xs:text-xl sm:text-2xl md:text-3xl font-semibold text-white select-none focus:outline-none placeholder-white placeholder-opacity-10"
                            />
                        </div>
                        <div className="flex flex-row gap-1 xs:gap-4 mt-2 items-baseline">
                            <p className="font-montserrat font-normal xs:font-semibold text-xs xs:text-sm sm:text-base text-white/50">Comments:</p>
                            <textarea
                                placeholder="Empty"
                                name="comments"
                                onChange={(e) => changeTask(e)}
                                value={taskInfo.comments}
                                autoComplete="off"
                                className="bg-transparent font-montserrat text-base xs:text-lg sm:text-xl md:text-2xl font-light text-white select-none focus:outline-none scrollbar placeholder-white placeholder-opacity-10 w-full h-32 resize-none"
                            />
                        </div>
                    </div>

                    <motion.button 
                        className="bg-[#FFA600] w-[95%] mx-auto sm:w-[300px] border-4 border-white mt-8"
                        initial={{
                            scale: 1
                        }}
                        whileHover={{
                            scale: 1.05,
                            transition: {
                                duration: 0.25
                            }
                        }}
                        whileTap={{
                            scale: 0.95,
                            transition: {
                                duration: 0.125
                            }
                        }}
                        onClick={() => createTask()}
                    >
                        <p className="font-montserrat text-black font-bold tracking-wide text-lg xs:text-xl sm:text-2xl py-1">CREATE</p>
                    </motion.button>
                </div>

                <div 
                    className="w-[20px] h-[20px] top-[15px] right-[15px] absolute hover:cursor-pointer"
                    onClick={() => setShowAddCustomTask(false)}
                >
                    <div className="w-[2px] h-[20px] left-[8px] rounded-full rotate-45 bg-white absolute" />
                    <div className="w-[2px] h-[20px] left-[8px] rounded-full rotate-[-45deg] bg-white absolute" />
                </div>
            </div>
        </div>
    )
}